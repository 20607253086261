import { connect } from 'react-redux';
import utils from 'utils';
import { actions } from '@ehi/global-marketing-interface';
import { setUpdatedSoldOutDateTime } from 'actions/locationFinder';
import { hasSelectedVehicleSelector } from 'selectors/reservationFlowSelectors';
import { setLocation, setPartnerRewardProgram, setPartnerRewardProgramMemberId } from 'actions/bookingWidget';
import {
  dateTimeValuesSelector,
  locationsSelector,
  locationHoursSelector,
  dateTimeInitiateRequestValuesSelector,
  initiateLocationModifiedSelector,
  locationsInitialValuesSelector,
} from 'selectors/progressBarSelectors';
import {
  getContractDetails,
  isPlanAheadSpecialsReservationSelector,
  isLastMinuteSpecialsReservationSelector,
  sessionReservationObjectSelector,
} from 'selectors/reservationSelectors';
import { getAdjustLocationId } from 'selectors/appSelectors';
import { initiateReservationFlow } from "actions/reservation/initiateReservationFlow";
import ProgressBarDatesTimesDrawer from './ProgressBarDatesTimesDrawer';

const mapStateToProps = (state, ownProps) => {
  const locationHours = locationHoursSelector(state, ownProps);
  const isLastMinuteSpecialsReservation = isLastMinuteSpecialsReservationSelector(state);
  const isPlanAheadSpecialsReservation = isPlanAheadSpecialsReservationSelector(state);

  const reservationObj = sessionReservationObjectSelector(state);
  const partner_reward_program = reservationObj?.get('partner_reward_program');

  const initiateLocationModifiedSelectorValues = initiateLocationModifiedSelector(state);
  const dateTimeInitiateRequestSelectorValues = dateTimeInitiateRequestValuesSelector(state);
  let dateTimeValues = dateTimeValuesSelector(state);
  let locations = locationsSelector(state);
  if (initiateLocationModifiedSelectorValues && dateTimeInitiateRequestSelectorValues) {
    dateTimeValues = dateTimeInitiateRequestSelectorValues;
    locations = locationsInitialValuesSelector(state);
  }

  return {
    values: dateTimeValues,
    pickupLocationId: locations?.pickupLocation?.id,
    returnLocationId: locations?.returnLocation?.id,
    ...locationHours,
    hasSelectedVehicle: hasSelectedVehicleSelector(state),
    contractDetails: getContractDetails(state),
    isPlanAheadSpecials: isPlanAheadSpecialsReservation,
    isLastMinuteOrPlanAheadSpecials: isLastMinuteSpecialsReservation || isPlanAheadSpecialsReservation,
    adjustLocationId: getAdjustLocationId(state),
    dateTimeInitiateRequestValues: dateTimeInitiateRequestSelectorValues,
    initiateLocationModifiedSelector: initiateLocationModifiedSelectorValues,
    ...locations,
    partner_reward_program,
  };
};

const mapDispatchToProps = {
  onDateChange: (timestamp, exchangeType) =>
    actions.reservations.initiateState.set('date', { timestamp, exchangeType }),
  onTimeChange: (timestamp, exchangeType) =>
    actions.reservations.initiateState.set('time', { timestamp, exchangeType }),
  setLocation,
  onSubmit: () =>
    initiateReservationFlow({
      saveLatestSelections: true,
      preserveBundleProfile: true,
      shouldCheckAvailability: utils.config.getIsSoldOutTrueAvailability(),
    }),
  clearDate: (exchangeType) => actions.reservations.initiateState.unset('date', { exchangeType }),
  dispatchSetUpdatedSoldOutDateTime: (bool) => setUpdatedSoldOutDateTime(bool),
  cleanMessages: actions.cleanMessages,
  setPartnerRewardProgram,
  setPartnerRewardProgramMemberId,
};

export default connect(mapStateToProps, mapDispatchToProps)(utils.toJS(ProgressBarDatesTimesDrawer));
