import React, { useState } from 'react';
import utils from 'utils';
import { analyticsInteraction } from 'utils/analytics';
import ANALYTICS from 'constants/analytics';
import PropTypes from 'prop-types';
import Title from 'components/Title';
import ServiceErrors from 'components/ServiceErrors';
import Button from 'components/Button';
import Form from 'components/Form/Form';
import RadioGroup from 'components/Form/RadioGroup';
import useRecaptchaEnterprise from 'hooks/useRecaptchaEnterprise';
import { LOOK_UP_BY_TYPE } from 'components/ViewModifyCancelApp/data/constants';
import { getMaskedPhoneNumber, getMaskedEmail } from 'utils/phoneInput';

const VerifyUI = (props) => {
  useRecaptchaEnterprise();
  const { phoneNumber, firstName, lastName, email, tripIdentifier, searchAction } = props;

  const [phoneOrEmailSelected, setPhoneOrEmailSelected] = useState(false);

  const getPhoneValue = (phoneNum) => {
    const isMasked = utils.fieldValidation.isMasked(phoneNum);
    if (isMasked) {
      return phoneNum;
    }
    return getMaskedPhoneNumber(phoneNum);
  };

  const getEmailValue = (emailId) => {
    const isMasked = utils.fieldValidation.isMasked(emailId);
    if (isMasked) {
      return emailId;
    }
    return getMaskedEmail(emailId);
  };

  const PHONE_NUMBER = 'phoneNumber';
  const EMAIL_ADDRESS = 'emailAddress';

  const onFormSubmit = (formData) => {
    let verify_data;
    if (formData.Lookup === PHONE_NUMBER) {
      verify_data = 'phone number';
    } else {
      verify_data = 'email address';
    }

    analyticsInteraction(ANALYTICS.UI_BUTTON, ANALYTICS.CONFIRM_MODIFY, ANALYTICS.CONTINUE, verify_data);

    let isEmail = false;
    if (formData && formData.Lookup === EMAIL_ADDRESS) {
      isEmail = true;
    }

    let formFieldData = {};
    formFieldData = {
      first_name: firstName,
      last_name: lastName,
      phone: phoneNumber,
      email,
      trip_identifier: tripIdentifier,
    };

    const lookupType = isEmail ? LOOK_UP_BY_TYPE.EMAIL : LOOK_UP_BY_TYPE.PHONE;
    searchAction(lookupType, formFieldData);
  };

  const onSelect = (e, input) => {
    const { value } = input;
    setPhoneOrEmailSelected(value);
  };

  return (
    <section className='rental-lookup-confirm theme--light-blue '>
      <Title type='h3' className='rental-lookup-confirm__title' text={utils.i18n('lets_confirm_link_to_be_sent_to')} />
      <span className='rental-lookup-confirm__error-msg'>
        <ServiceErrors />
      </span>
      <Form onSubmit={onFormSubmit}>
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit} autoComplete='off' noValidate>
            <RadioGroup
              name='Lookup'
              onChange={onSelect}
              buttons={[
                {
                  label: utils.i18n('common_phone_number'),
                  value: PHONE_NUMBER,
                  description: phoneNumber && getPhoneValue(phoneNumber),
                },
                {
                  label: utils.i18n('enrollment_form_email_address'),
                  value: EMAIL_ADDRESS,
                  description: email && getEmailValue(email),
                },
              ]}
              theme={'darkest-blue'}
            />
            {phoneOrEmailSelected && phoneOrEmailSelected === PHONE_NUMBER && (
              <div className='rental-lookup-confirm__desc'>
                <p>{utils.i18n('rental_lookup_radio_terms')}</p>
              </div>
            )}
            <div className='rental-lookup-confirm__button'>
              <Button
                type='submit'
                className='rental-lookup-form__search-button'
                disabled={!(phoneOrEmailSelected && phoneOrEmailSelected)}
              >
                <span>{utils.i18n('common_continue')}</span>
              </Button>
            </div>
          </form>
        )}
      </Form>
    </section>
  );
};

VerifyUI.propTypes = {
  phoneNumber: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  email: PropTypes.string,
  tripIdentifier: PropTypes.string,
  searchAction: PropTypes.func,
};

export default VerifyUI;
