/* eslint max-classes-per-file: 0 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDatePicker, { CalendarContainer, registerLocale } from 'react-datepicker-isobar';
import utils from 'utils';
import { isInViewport } from 'utils/accessibility/viewport';
import { isDateRestrictedByContract } from 'utils/reservation';
import { formatDateTimeStampAndReturnDateTimeObj } from 'utils/date';
import { i18n } from 'utils/i18n';
import RESERVATIONS from 'constants/reservations';
import cn from 'classnames';
import FieldControl from 'components/Form/FieldControl';
import Button from 'components/Button';

/**
 * DatePicker Description:
 * implementation of react-datepicker into our app
 * uses a button as a custom input
 * wrapped in FieldControl to behave and appear like our other fields
 * handles one half of a date range
 *
 * @param {object} props - React Props
 * @param {object} props.breakpoint
 * @param {*} props.fieldProps - any props to get applied to the Final Form FieldControl
 * @param {Date} props.startDate - beginning of the date range
 * @param {Date} props.endDate - end of the date range
 * @param {function} props.onChange - function fired when any date value changes
 * @param {Date} props.selected - the date that THIS picker is choosing
 * @param {Date} props.minDate
 * @param {Date} props.maxDate
 * @param {number} [props.monthsShown=2] - number of months that will appear in the dropdown at one time
 * @param {boolean} props.selectsStart - will determine that this picker handles the start of the date range
 * @param {boolean} props.selectsEnd - will determine that this picker handles the end of the date range
 * @param {string} props.helpText - non-error text to provide instruction to the user
 * @param {string} props.errorText - describes what went wrong
 * @param {object|boolean} props.eligibility - branch location hours/days from GMI that gets used to determine disabled/enabled days on the calendar
 * @param {string} props.label - field label
 * @param {boolean} props.rightAlignDatepicker - positions the datepicker to the right edge
 * @param {boolean} props.timeAndDatePickerFullHeight
 *
 * @return {JSX}
 */
const CustomContainer = React.forwardRef((props, ref) => {
  const {
    className,
    children,
    helpText,
    handleClose,
    isMobile,
    showGuidedError,
    renderGuidedError,
    smallWrapper,
    timeAndDatePickerFullHeight,
    contractRestrictions,
    isRental,
  } = props;

  return (
    <div
      className={cn('react-datepicker__custom-wrapper component-theme--light', {
        'react-datepicker__custom-wrapper--half-width': smallWrapper,
        'react-datepicker__custom-wrapper--full-height': timeAndDatePickerFullHeight,
      })}
    >
      {(helpText || showGuidedError || isMobile) && (
        <div className='react-datepicker__messaging'>
          {showGuidedError && renderGuidedError()}
          {!showGuidedError && helpText && (
            <>
              <div className='react-datepicker__help-message'>{helpText}</div>
              {contractRestrictions && <div className='react-datepicker__special-label'>{i18n('common_special')}</div>}
            </>
          )}
          {isMobile && (
            <Button
              plain
              className='react-datepicker__close'
              onClick={handleClose}
              type='button'
              aria-label={i18n('common_close')}
            >
              {i18n('common_close')}
            </Button>
          )}
        </div>
      )}
      <CalendarContainer className={className}>
        {children}
        <span className='scroll-reference' ref={ref} />
      </CalendarContainer>
      {isRental && (
        <div className='react-datepicker__rental-footer'>
          <div>
            <span className='react-datepicker__today-icon'>##</span>
            {i18n('todays_date')}
          </div>
          <div>
            <span className='react-datepicker__exceeds-icon'>##</span>
            {i18n('date_exceeds_max_duration_rental')}
          </div>
        </div>
      )}
    </div>
  );
});

class CustomInput extends Component {
  handleClick = () => {
    this.props.onInputClick();
    this.props.onClick();
  };

  render() {
    const {
      className,
      disabled,
      name,
      value,
      setCustomInputRef,
      datePickerRef,
      label,
      selectedProp,
      asterisk,
      ...props
    } = this.props;
    delete props.onInputClick;
    delete props.onClick;
    const displayValue = value
      ? utils.gmi
          .getDateTimeObjFromTs(value, ['YYYY-MM-DD'])
          .format(utils.config.getDateTimeShortFormat('day_date_month'))
      : '';

    const ariaLabel = label;
    const asteriskValue = asterisk ? 'required' : displayValue;
    const displayedValueString = displayValue.length > 0 ? displayValue : asteriskValue;
    const datePickeOpen = datePickerRef?.state?.open;
    const ariaExpanded = String(datePickeOpen) === 'undefined' ? false : datePickerRef?.state?.open;
    const selectionMade = selectedProp !== null;

    const requiredAriaLabel = `${ariaLabel} ${displayedValueString}`;
    return (
      <Button
        plain
        {...props}
        onClick={this.handleClick}
        disabled={disabled}
        type='button'
        className={cn(className, 'field-button', 'dropdown', { 'aria-expanded': ariaExpanded })}
        name={name}
        buttonRef={setCustomInputRef}
        // AWR-6654 - Accessibility - The aria-expanded and aria-live attributes are incorrect and should be removed.
        // The aria-expanded attribute will be reported to assistive technology users as a disclosure button, when it isn't a disclosure button.
        // aria-live='polite' // so that a screen reader catches the updated value
        // aria-expanded={ariaExpanded}
        aria-label={requiredAriaLabel}
        aria-pressed={selectionMade}
      >
        {displayValue}
      </Button>
    );
  }
}

class DatePicker extends Component {
  constructor() {
    super();
    this.state = {
      inline: false,
      closeDatePickerContainer: false,
    };
    // since we're generating our own locale, it doesn't matter what we call this
    // just make sure we use the 'alamo' locale name on the <Datepicker locale='alamo' />
    registerLocale('alamo', utils.localization.buildDatePickerLocale('alamo'));
  }

  componentDidUpdate(prevProps) {
    const { input, meta, selected } = this.props;
    if (!meta.data.isFocusedActive && prevProps.meta.data.isFocusedActive) {
      input.onBlur();
    }
    // If input value is empty but there is a selected date, fire input change (normally on load value)
    if (!input.value && selected) {
      this.handleChange(selected);
    }
  }

  setDatePickerRef = (el) => {
    this.datePickerRef = el;
  };

  setCustomInputRef = (el) => {
    this.customInputRef = el;
  };

  setCalendarContainerRef = (el) => {
    this.calendarContainerRef = el;
    this.calendarContainerRef && this.scrollToSelectedMonth();
  };

  scrollToSelectedMonth = () => {
    const { breakpoint } = this.props;
    if (breakpoint.isMobile) {
      let month = document
        .querySelector('.react-datepicker__day--selected')
        ?.closest('.react-datepicker__month-container');
      if (!month) {
        month = document
          .querySelector('.react-datepicker__day--range-start')
          ?.closest('.react-datepicker__month-container');
      }
      this.calendarContainerRef.parentElement.scrollTop = month?.offsetTop;
    }
  };

  handleInputClick = () => {
    // If datepicker button is pressed while calendar is already open, closes the calendar. Needs to be in a setTimeout
    // to override ReactDatePicker automatically opening on every click
    if (this.datePickerRef.state.open) {
      setTimeout(() => this.datePickerRef.setOpen(false));
    }

    // Need to blur the input after popping open the calendar in mobile, otherwise first click on the calendar doesn't register
    if (this.props.breakpoint.isMobile) {
      this.customInputRef.blur();
      utils.scrollLock();
    }

    // resetting the state value to false on every click of pickup and return date
    this.setState({
      closeDatePickerContainer: false,
    });
  };

  cleanUpScrollLock = () => {
    const { breakpoint } = this.props;
    if (breakpoint.isMobile) {
      utils.scrollLock({ toggleValue: false });
    }
  };

  handleClickOutside = () => {
    // For some reason, this handleClickOutside was causing issues with selecting a date on progress bar in desktop.
    // Probably some weird interaction with ReactDatePicker which has its own built in clickOutsideFunctionality.
    // Putting it into setTimeout solves this issue
    setTimeout(this.props.onBlur);

    // should only remove scroll lock here if the datepicker is actually opened/focused
    if (this.datePickerRef.state.open) {
      this.cleanUpScrollLock();
    }
  };

  handleChange = (date, e) => {
    if (e && typeof e.preventDefault === 'function') {
      e.preventDefault();
    }

    const { onChange, breakpoint } = this.props;
    onChange && onChange(date);
    // focus the button/input after selecting a date except in mobile
    !breakpoint.isMobile && this.customInputRef.focus();
    this.cleanUpScrollLock();
    this.setState({
      closeDatePickerContainer: true,
    });
  };

  handleClose = () => {
    this.datePickerRef.setOpen(false);
    this.cleanUpScrollLock();
  };

  handleScrollIntoView = (elem) => {
    // If calendar element is not in viewport, scroll to it
    !isInViewport(elem, 100) && elem.scrollIntoView({ block: 'center' });
  };

  handleFocus = (event) => {
    const { onFocus } = this.props;
    const isCustomInputEventTarget = event.nativeEvent.target === this.customInputRef;
    isCustomInputEventTarget && onFocus(event);

    this.handleScrollIntoView(event.target);

    // Trap Focus when user has datepicker open
    utils.trapFocus(
      '.react-datepicker__custom-wrapper',
      '.react-datepicker__close, .react-datepicker__day[tabindex="0"]:not(.react-datepicker__day--outside-month)',
      (el) => window.innerWidth < 1279 && el
    );

    // In some cases both click and focus events are triggered together and can one reverse the other (datepicker blink).
    // To fix that we have to wait for click event to finish, if triggered, and then check for datepicker open state and
    // also if the field is FocusedActive by the guided flow.
    // Also, checks for errors on focus so that the datepicker is properly opened when attempting to submit
    // a form with an empty field on the BW (AWR-1945)
    setTimeout(() => {
      const { meta, showGuidedError, selected, startDate, endDate } = this.props;
      const isClosedAndFocused = !this.datePickerRef?.state.open && meta.data.isFocusedActive;
      // Checking if custom input is the target here prevents misfires when closing the datepicker on mobile
      const hasErrorAndIsTargeted = isCustomInputEventTarget && showGuidedError;
      if (isClosedAndFocused || hasErrorAndIsTargeted) {
        this.datePickerRef.setOpen(true);

        // Need to blur the input after popping open the calendar in mobile
        // otherwise first click on the calendar doesn't register
        // (only matters when popping up when there's a submit error)
        if (this.props.breakpoint.isMobile) {
          this.customInputRef.blur();
          utils.scrollLock();
        }
      }

      // In some cases even after selecting the date , datepicker remains open
      // To fix that we are checking the below values to that have selected values
      // set value false to datePickerRef setOPen function ,when the below condition is true
      if ((startDate || endDate) && selected && isClosedAndFocused && this.state.closeDatePickerContainer) {
        this.datePickerRef.setOpen(false);
      }
    }, 100); // This time is a magic number to wait for click event finish
  };

  handleBlur = (e) => {
    !this.datePickerRef.state.open && e.nativeEvent.target === this.customInputRef && this.props.onBlur(e);
  };

  handleKeyDown = (e) => {
    // custom inputs aren't regaining focus if you escape out of the popper
    if (e.key === 'Escape' && this.datePickerRef.state.open) {
      e.stopPropagation(); // we don't want "Escape" to trigger on any parent elements, such as modals
      this.cleanUpScrollLock();
      this.customInputRef.focus();
    }
  };

  formatWeekDay = (day) => day[0];

  getIsDateRestrictedByContract = (date) => {
    const { selectsStart, contractRestrictions } = this.props;

    return contractRestrictions
      ? isDateRestrictedByContract(
          selectsStart ? RESERVATIONS.EXCHANGE_TYPE_PICKUP : RESERVATIONS.EXCHANGE_TYPE_RETURN,
          formatDateTimeStampAndReturnDateTimeObj(date),
          contractRestrictions
        )
      : true;
  };

  renderDayContents = (day, date) => (
    <>
      {!this.getIsDateRestrictedByContract(date) && <span className='react-datepicker__day-number-tag' />}
      <span className='react-datepicker__day-number'>{utils.gmi.getDateTimeObjFromTs(date).date()}</span>
    </>
  );

  renderCustomContainer = (customProps) => (props) =>
    <CustomContainer {...customProps} {...props} ref={this.setCalendarContainerRef} />;

  // Checking excludeDates for pickup date from 'AFTER' because, Counter Hours should not override the PickUp Hours.
  getExcludedDaysForPickup = (eligibility) =>
    eligibility
      ? Object.keys(eligibility).reduce((acc, curr) => {
          if (!eligibility[curr]?.AFTER) {
            if (eligibility[curr].STANDARD.closed) {
              acc.push(utils.gmi.getDateTimeObjFromTs(curr).toDate());
            }
          } else if (eligibility[curr]?.AFTER?.closed) {
            acc.push(utils.gmi.getDateTimeObjFromTs(curr).toDate());
          }
          return acc;
        }, [])
      : [];

  // Checking excludeDates for retun date from 'DROP' because, Counter Hours should not override the Drop Hours.
  getExcludedDaysForReturn = (eligibility) =>
    eligibility
      ? Object.keys(eligibility).reduce((acc, curr) => {
          if (!eligibility[curr]?.DROP) {
            if (eligibility[curr].STANDARD.closed) {
              acc.push(utils.gmi.getDateTimeObjFromTs(curr).toDate());
            }
          } else if (eligibility[curr]?.DROP?.closed) {
            acc.push(utils.gmi.getDateTimeObjFromTs(curr).toDate());
          }
          return acc;
        }, [])
      : [];

  setDayClassNames = (date) => {
    const { startDate, endDate, maxDate } = this.props;

    return cn({
      'react-datepicker__day--range-start': startDate && date.toISOString() === startDate.toISOString(),
      'react-datepicker__day--range-end': endDate && date.toISOString() === endDate.toISOString(),
      'react-datepicker__day--special': !this.getIsDateRestrictedByContract(date),
      'react-datepicker__day--after-max-date': maxDate && date > maxDate,
    });
  };

  getMonthsShown = (monthsShown) => {
    const { isMobile, isTablet } = this.props.breakpoint;
    if (isMobile) {
      return 13;
    }
    if (isTablet) {
      return 1;
    }
    return monthsShown;
  };

  handleCalendarOpen = () => {
    const today = document.getElementsByClassName('react-datepicker__day--today')[0];
    const dayRangeStart = document.getElementsByClassName('react-datepicker__day--range-start')[0];
    const dayRangeEnd = document.getElementsByClassName('react-datepicker__day--range-end')[0];

    if (dayRangeStart) {
      // calendar has been opened in a different month
      dayRangeStart.focus();
      this.handleScrollIntoView(dayRangeStart);
    } else if (today) {
      today.focus();
      this.handleScrollIntoView(today);
    } else {
      dayRangeEnd?.focus();
      dayRangeEnd && this.handleScrollIntoView(dayRangeEnd);
    }
    // Accessibility - The aria-pressed attribute will be reported to assistive technology users as a selected date.
    dayRangeStart?.setAttribute('aria-pressed', true);
    dayRangeEnd?.setAttribute('aria-pressed', true);
  };

  render() {
    const {
      breakpoint,
      helpText,
      meta,
      monthsShown,
      selected,
      eligibility,
      guided,
      startDate,
      endDate,
      showGuidedError,
      renderGuidedError,
      label,
      rightAlignDatepicker,
      timeAndDatePickerFullHeight,
      contractRestrictions,
      asterisk,
      id,
      isRental,
    } = this.props;
    const { isMobile } = breakpoint;
    const excludeDates =
      id === 'pickupDate' ? this.getExcludedDaysForPickup(eligibility) : this.getExcludedDaysForReturn(eligibility);

    return (
      <div
        className='react-datepicker__outer-wrapper'
        ref={this.clickOutsideRef}
        onBlur={this.handleBlur}
        onFocus={this.handleFocus}
      >
        <ReactDatePicker
          onCalendarOpen={this.handleCalendarOpen}
          locale='alamo'
          {...this.props}
          startDate={startDate}
          endDate={endDate}
          ref={this.setDatePickerRef}
          inline={isMobile && this.state.inline}
          selected={selected}
          openToDate={selected}
          withPortal={isMobile}
          monthsShown={this.getMonthsShown(monthsShown)}
          showDisabledMonthNavigation
          calendarClassName={'datePicker'}
          onChange={this.handleChange}
          formatWeekDay={this.formatWeekDay}
          renderDayContents={this.renderDayContents}
          calendarContainer={this.renderCustomContainer({
            meta,
            guided,
            helpText,
            handleClose: this.handleClose,
            isMobile,
            showGuidedError,
            renderGuidedError,
            smallWrapper: this.getMonthsShown(monthsShown) === 1,
            timeAndDatePickerFullHeight,
            contractRestrictions,
            isRental,
          })}
          dateFormat='yyyy-MM-dd' // this format helps the datepicker match the format above. intentionally lowercase "y" and "d"
          excludeDates={excludeDates}
          onKeyDown={this.handleKeyDown}
          customInput={
            <CustomInput
              setCustomInputRef={this.setCustomInputRef}
              onInputClick={this.handleInputClick}
              label={label}
              datePickerRef={this.datePickerRef}
              selectedProp={selected}
              asterisk={asterisk}
            />
          }
          preventOpenOnFocus={true} // prevent lib focus handling in order to use our custom logic
          dayClassName={this.setDayClassNames}
          popperPlacement={rightAlignDatepicker ? 'bottom-end' : 'bottom-start'}
          popperModifiers={{
            flip: {
              enabled: false,
            },
          }}
        />
      </div>
    );
  }
}

DatePicker.propTypes = {
  // container props:
  breakpoint: PropTypes.object,

  // parent props:
  fieldProps: PropTypes.any,
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date),
  onChange: PropTypes.func,
  selected: PropTypes.instanceOf(Date),
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  monthsShown: PropTypes.number,
  selectsStart: PropTypes.bool,
  selectsEnd: PropTypes.bool,
  helpText: PropTypes.string,
  errorText: PropTypes.string,
  eligibility: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  label: PropTypes.string,
  rightAlignDatepicker: PropTypes.bool,
  timeAndDatePickerFullHeight: PropTypes.bool,
  isRental: PropTypes.bool,
};

DatePicker.defaultProps = {
  monthsShown: 2,
};

const Picker = utils.withClickOutside(DatePicker);

const DatePickerField = (props) => (
  <FieldControl {...props} dropdown type='datepicker'>
    <Picker />
  </FieldControl>
);

export default DatePickerField;
