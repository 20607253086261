import React, { Fragment, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import utils from 'utils';
import { breakpointSelector } from 'selectors/breakpoint';
import RESERVATIONS from 'constants/reservations';
import ERRORS from 'constants/errors';
import GenericError from 'components/GenericError';
import Button from 'components/Button';
import FormattedPrice from 'components/FormattedPrice';
import CardHolderDisclaimer from 'components/CardHolderDisclaimer';

const { CARD_ALERT } = RESERVATIONS.PREPAY_UI_STEPS;
const { PRIORITY_ERROR } = ERRORS;

const PaymentSection = ({
  handleTogglePrepay,
  newTotal,
  estimatedTotalPayment,
  isForeignCurrency,
  showLearnMoreModal,
  existingPaymentTotal,
  amountChargedOpposite,
  prepayStep,
  prepayFailed,
  collectNewPaymentCard,
}) => {
  const { isMobile } = useSelector(breakpointSelector);

  const paymentSubText = useCallback(() => {
    if (isForeignCurrency) {
      return 'rental_details_estimated_total';
    }
    if (collectNewPaymentCard) {
      return `true_modify_prepay_changed_rate_new_payment${isMobile ? '_mobile' : ''}`;
    }
    return 'review_payment_section_charged_text';
  }, [isForeignCurrency, collectNewPaymentCard, isMobile]);

  const cardAlert = prepayStep === CARD_ALERT;

  return (
    <>
      {!cardAlert && (
        <>
          <Button
            link
            className='link--text review__complete-reservation__prepay--toggle'
            onClick={handleTogglePrepay}
            data-dtm-track='review|pay_later|select'
          >
            {utils.i18n('review_complete_reservation_switch_to_pay_later_label', {
              0: amountChargedOpposite,
            })}
          </Button>

          {prepayFailed && (
            <GenericError
              errors={[
                { priority: PRIORITY_ERROR, message: utils.i18n('review_payment_section_authorization_denied_error') },
              ]}
              description={utils.i18n(
                'review_payment_section_authorization_denied_description',
                [
                  <Button key={0} onClick={handleTogglePrepay} link>
                    {utils.i18n('review_payment_section_authorization_denied_switch_method')}
                  </Button>,
                  <FormattedPrice key={1}>{amountChargedOpposite}</FormattedPrice>,
                ],
                { jsx: true }
              )}
            />
          )}

          <div className='payment-section__pay-info-container'>
            <p className='payment-section__sub-text'>{utils.i18n(paymentSubText())}</p>

            <FormattedPrice classNames='payment-section__price'>{newTotal}</FormattedPrice>
          </div>
          {isForeignCurrency && (
            <div className='payment-section__amount-charged'>
              {utils.i18n(
                'rental_details_you_will_be_charged',
                [
                  <Fragment key='total-price-charged-0'>{estimatedTotalPayment.code}</Fragment>,
                  <FormattedPrice key='total-price-charged-1'>
                    {utils.getFormattedPrice(estimatedTotalPayment)}
                  </FormattedPrice>,
                ],
                { jsx: true }
              )}
              <span className='payment-section__amount-charged__converted-copy' onClick={showLearnMoreModal}>
                {utils.i18n('rental_details_view_currency_conversion')}
              </span>
            </div>
          )}

          {collectNewPaymentCard && (
            <div className='review__complete-reservation__prepay-refund-message'>
              <p className='review__complete-reservation__total-description'>
                {utils.i18n(
                  'true_modify_prepay_changed_rate_refund_copy',
                  [
                    <FormattedPrice key='0'>{existingPaymentTotal}</FormattedPrice>,
                    <FormattedPrice key='1'>{newTotal}</FormattedPrice>,
                  ],
                  { jsx: true }
                )}
              </p>
            </div>
          )}
        </>
      )}

      <div className='payment-section__border-divider'>
        <CardHolderDisclaimer enableInUSorCAonly />

        <p
          className={cn('payment-section__secure-connection-message', {
            'payment-section__secure-connection-message--gbo-error': cardAlert,
          })}
        >
          {utils.i18n('review_payment_section_secure_connection')}
        </p>
      </div>

      {cardAlert && (
        <>
          <div className='payment-section__price-container'>
            <p className='payment-section__res-total'>{utils.i18n('gbo_error_total_price_label')}</p>
            <FormattedPrice classNames='payment-section__res-total-amount'>{newTotal}</FormattedPrice>
          </div>
        </>
      )}
    </>
  );
};

PaymentSection.propTypes = {
  handleTogglePrepay: PropTypes.func,
  newTotal: PropTypes.string,
  existingPaymentTotal: PropTypes.string,
  amountChargedOpposite: PropTypes.string,
  prepayStep: PropTypes.string,
  prepayFailed: PropTypes.bool,
  collectNewPaymentCard: PropTypes.bool,
  estimatedTotalPayment: PropTypes.object,
  isForeignCurrency: PropTypes.bool,
  showLearnMoreModal: PropTypes.func,
};

export default PaymentSection;
