import React from 'react';
import PropTypes from 'prop-types';
import utils from 'utils';
import FormattedPrice from 'components/FormattedPrice';

/**
 * @param {string} rate.unit_rate_type    - Rate unit type (i.e. DAILY, WEEKLY, MONTH or EXTRA_DAILY)
 * @param {object} rate.unit_amount_view  - Object containing formatted price data
 * @return {string} content string including the formatted price
 */
const getFormattedRateDisplay = ({
  unit_rate_type,
  unit_amount_payment,
  unit_amount_view,
  isDestinationCurrencySelected,
}) =>
  utils.i18n(`progress_bar_vehicle_drawer_vehicle_rate_${unit_rate_type}`, [
    utils.getFormattedPrice(isDestinationCurrencySelected ? unit_amount_payment : unit_amount_view),
  ]);

/**
 * Component for displaying the Total Price on the Progress Bar Drawer
 * @param {object} props              - React Props
 * @param {object} props.vehicleRate  - Object containing total and incremental rates data for the selected vehicle class
 * @param {object} props.breakpoint   - Breakpoint state flags
 * @return {JSX} TotalPriceVehicleProgressBarDrawer component
 */
const TotalPriceVehicleProgressBarDrawer = ({ vehicleRate = {}, breakpoint, isDestinationCurrencySelected }) => {
  const { total_price_view, total_price_payment, rates = [] } = vehicleRate;
  const [mainRate, ...additionalRates] = rates;

  const priceDisplay = utils.getFormattedPrice(isDestinationCurrencySelected ? total_price_payment : total_price_view);
  mainRate.isDestinationCurrencySelected = isDestinationCurrencySelected;
  const incrementalPricingDisplay = getFormattedRateDisplay(mainRate);

  return (
    <div className='rental-details__rate-pricing'>
      {breakpoint.isDesktop && utils.config.getIsIncrementalPricingEnabled() ? (
        <>
          <FormattedPrice classNames={'rental-details__rate-pricing__main'}>{incrementalPricingDisplay}</FormattedPrice>
          {additionalRates?.map(additionalRate => (
            <FormattedPrice key={additionalRate.unit_rate_type} classNames={'rental-details__rate-pricing__additional'}>
              {getFormattedRateDisplay(additionalRate, isDestinationCurrencySelected)}
            </FormattedPrice>
          ))}
        </>
      ) : (
        <FormattedPrice classNames={'rental-details__rate-pricing__main'}>{priceDisplay}</FormattedPrice>
      )}
    </div>
  );
};

TotalPriceVehicleProgressBarDrawer.propTypes = {
  vehicleRate: PropTypes.object,
  breakpoint: PropTypes.object,
};

export default TotalPriceVehicleProgressBarDrawer;
