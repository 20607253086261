import React, { Fragment } from 'react';
import { i18n } from 'utils/i18n';
import { getFormattedPriceRounded } from 'utils/prices';
import FormattedPrice from 'components/FormattedPrice';

export const filters = [
  {
    keyPath: 'filters.CLASS.filter_code',
    type: 'checkbox',
    showTotalLabel: true,
    category: 'CLASS',
    labelHandler: (label, value, lowestCarFilterPrices) => {
      const lowestRate = lowestCarFilterPrices?.car_classes[value];
      const doesPriceIncludeFormat = lowestRate?.format;

      return doesPriceIncludeFormat
        ? i18n(
            'vehicle_select_filter_price_label',
            [
              <Fragment key='0'>{label}</Fragment>,
              <FormattedPrice key='1'>{getFormattedPriceRounded(lowestRate)}</FormattedPrice>,
            ],
            { jsx: true }
          )
        : label;
    },
  },
  {
    keyPath: 'filters.PASSENGERS.filter_code',
    type: 'checkbox',
    showTotalLabel: true,
    category: 'PASSENGERS',
    labelHandler: (label, value, lowestCarFilterPrices) => {
      const lowestRate = lowestCarFilterPrices?.car_capacity[value];
      const doesPriceIncludeFormat = lowestRate?.format;

      return doesPriceIncludeFormat
        ? i18n(
            'vehicle_select_filter_price_label',
            [
              <Fragment key='0'>{i18n('vehicle_filters_and_up_passengers', [label])}</Fragment>,
              <FormattedPrice key='1'>{getFormattedPriceRounded(lowestRate)}</FormattedPrice>,
            ],
            { jsx: true }
          )
        : label;
    },
  },
  {
    keyPath: 'filters.DRIVE.filter_code',
    type: 'checkbox',
    showTotalLabel: true,
    category: 'DRIVE',
    labelHandler: (label, value, lowestCarFilterPrices) => {
      const lowestRate = lowestCarFilterPrices?.car_drive?.[value];
      const doesPriceIncludeFormat = lowestRate?.format;
      label = i18n(`vehicle_filter_drive_type_${value}`);

      return doesPriceIncludeFormat
        ? i18n(
            'vehicle_select_filter_price_label',
            [
              <Fragment key='0'>{label}</Fragment>,
              <FormattedPrice key='1'>{getFormattedPriceRounded(lowestRate)}</FormattedPrice>,
            ],
            { jsx: true }
          )
        : label;
    },
  },
  {
    keyPath: 'filters.BAGS.filter_code',
    isRadio: true,
    type: 'select',
    category: 'BAGS',
    optionDecorateKey: 'vehicle_filters_and_up_passengers',
  },
  {
    keyPath: 'filters.TRANSMISSION.filter_code',
    type: 'select',
    isSingleSelect: true,
    category: 'TRANSMISSION',
  },
];

export const sorting = {
  name: 'sortBy',
  initialValue: 'featured',
  buttons: [
    {
      sortKey: 'no_sort_by', // this option is for getting the default order from GBO, so no sort will be applied
      label: i18n('vehicle_sort_by_featured'),
      value: 'featured',
    },
    {
      sortKey: ['charges', 'PAYLATER', 'total_price_payment', 'amount'],
      isDesc: false,
      isNumber: true,
      label: i18n('vehicle_sort_by_price_low'),
      value: 'low_high',
    },
    {
      sortKey: ['charges', 'PAYLATER', 'total_price_payment', 'amount'],
      isDesc: true,
      isNumber: true,
      label: i18n('vehicle_sort_by_price_high'),
      value: 'high_low',
    },
  ],
};
