import React from 'react';
import { useSelector } from 'react-redux';
import utils from 'utils';
import { i18n } from 'utils/i18n';
import THEMES from 'constants/themes';
import { CM_TESTING } from 'constants/urlParam';
import { breakpointSelector } from 'selectors/breakpoint';
import { authenticatedSelector } from 'selectors/profileSelectors';
import RentalTypeOptions from 'components/RentalTypeOptions';
import ReservationLookupTemplate from './ReservationLookupTemplate';
import SearchReservation from './SearchReservation';

/**
 * Reservation Lookup Generic React Component
 *
 * @return {JSX}
 */
const ReservationLookup = () => {
  const isAuth = useSelector(authenticatedSelector);
  const breakpoints = useSelector(breakpointSelector);
  const { isDesktop } = breakpoints;
  const enableConfirmModifyReturnUnauthLookup = utils.config.getEnableConfirmModifyReturnUnauthLookup();
  const params = new URLSearchParams(location.search);

  const confirmModifyTesting = params.has(CM_TESTING);
  const showSideSection = (isAuth || !enableConfirmModifyReturnUnauthLookup) && isDesktop;
  const showUnauthLookupStart = (enableConfirmModifyReturnUnauthLookup || confirmModifyTesting) && !isAuth;

  const renderLookupRentalUI = (showStart) => {
    if (showStart) {
      return <RentalTypeOptions />;
    }
    return <SearchReservation />;
  };

  return (
    <ReservationLookupTemplate
      headerSectionTheme={THEMES.DARK_BLUE}
      mainSectionTheme={THEMES.LIGHT_BLUE}
      titleText={i18n('view_modify_cancel')}
      noMinHeight
      noMarignTop
      showSideSection={showSideSection}
    >
      {renderLookupRentalUI(showUnauthLookupStart)}
    </ReservationLookupTemplate>
  );
};

export default ReservationLookup;
