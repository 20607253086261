import React from 'react';
import utils from 'utils';
import { THEMES } from 'constants';
import Checkbox from 'components/Form/Checkbox';
import Button from 'components/Button';
import FieldGroupCard from '../FieldGroupCard';
import { propTypes } from './prepayConfirmationCardPropTypes';

const PrepayConfirmationCard = ({
  handleTogglePrepay,
  oppositeEstimatedTotal,
  prepayTermsLink,
  disablePrepayPaylaterButton,
}) => (
  <FieldGroupCard>
    <div className='review__section-card__field-row review__complete-reservation__terms-row'>
      <Checkbox
        className='review__complete-reservation__prepay--terms-and-conditions'
        name='payment_info.accept_prepay_terms'
        label={utils.i18n('review_form_prepay_terms_and_conditions', { 0: prepayTermsLink }, { jsx: true })}
        theme={THEMES.LIGHT}
        required={utils.i18n('field_required_dont_forget_to_enter', [
          utils.i18n('review_form_prepay_terms_and_conditions_error'),
        ])}
        validate={utils.fieldValidation.isTrueBool}
      />
    </div>
    <Button
      className='review__complete-reservation__prepay--submit-button'
      data-dtm-track='review|pre_pay|continue_to_payment'
      disabled={disablePrepayPaylaterButton}
      type='submit'>
      {utils.i18n('review_form_submit_button_continue_to_payment_label')}
    </Button>
    <div className='review__complete-reservation__total-row'>
      <Button
        className='link--text review__complete-reservation__prepay--toggle'
        data-dtm-track='review|pay_later|select'
        link
        onClick={handleTogglePrepay}>
        {utils.i18n('review_complete_reservation_switch_to_pay_later_label', {
          0: oppositeEstimatedTotal?.format,
        })}
      </Button>
    </div>
  </FieldGroupCard>
);

PrepayConfirmationCard.propTypes = propTypes;

export default PrepayConfirmationCard;
