import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { i18n } from 'utils/i18n';
import THEMES from 'constants/themes';
import { hasInvalidPhoneErrorSelector } from 'selectors/errorSelectors';
import useRecaptchaEnterprise from 'hooks/useRecaptchaEnterprise';
import Title from 'components/Title';
import NoCurrentRentalModal from 'components/modals/NoCurrentRentalModal';
import ReservationLookupTemplate from 'components/ReservationLookup/ReservationLookupTemplate';
import LookUpRentalForm from './LookUpRentalForm';
import ServiceErrors from 'components/ServiceErrors';

const LookUpByUI = ({ breakpoint, lookupType, showNoCurrentRentalModal }) => {
  const titleText = 'lookup_rental';
  const showSideSection = breakpoint.isDesktop;
  
  const hasPhoneNumberError = useSelector(hasInvalidPhoneErrorSelector)

  useRecaptchaEnterprise();

  return (
    <ReservationLookupTemplate
      headerSectionTheme={THEMES.DARK_BLUE}
      mainSectionTheme={THEMES.LIGHT_BLUE}
      titleText={titleText}
      noMinHeight
      noMarignTop
      showSideSection={showSideSection}
      isLookupRentalUI
    >
      {hasPhoneNumberError && <ServiceErrors />}
      <div>
        <Title type='h2' text={i18n('rental_information')} />
        <span className='rental-lookup__required' aria-hidden={true}>
          {i18n('rental_required')}
        </span>
      </div>
      <LookUpRentalForm lookupType={lookupType} showNoCurrentRentalModal={showNoCurrentRentalModal} />
      <NoCurrentRentalModal />
    </ReservationLookupTemplate>
  );
};

LookUpByUI.propTypes = {
  breakpoint: PropTypes.object,
  lookupType: PropTypes.string,
  showNoCurrentRentalModal: PropTypes.func,
};

export default LookUpByUI;