import React from 'react';
import { useSelector } from 'react-redux';
import { safeToJS } from 'utils/immutable';
import useActions from 'hooks/useActions';
import MODAL from 'constants/modals';
import {
  getReturnDateAndTime,
  getRentalReturnLocation,
  getRentalLastReturnDate,
  getPickUpDateAndTime,
  getContractDetails,
} from 'selectors/rentalTicketSelectors';
import { initiate } from 'actions/modifyRental';
import { openModal } from "actions/modal/openModal";
import ModifyReturnWidget from './ModifyReturnWidget';

const ModifyReturnWidgetContainer = (props) => {
  const showCreditCardErrorModal = useActions(() => openModal(MODAL.CREDIT_CARD_DATE_EXPIRATION_MODAL));

  const initiateModifyRental = useActions(initiate);
  const returnDateAndTime = useSelector(getReturnDateAndTime);
  const returnLocation = useSelector(getRentalReturnLocation);
  const lastReturnDate = useSelector(getRentalLastReturnDate);
  const pickUpDateAndTime = useSelector(getPickUpDateAndTime);
  const contractDetails = safeToJS(useSelector(getContractDetails));

  return (
    <ModifyReturnWidget
      {...{
        showCreditCardErrorModal,
        initiateModifyRental,
        returnDateAndTime,
        returnLocation,
        lastReturnDate,
        pickUpDateAndTime,
        contractDetails,
        ...props,
      }}
    />
  );
};

export default ModifyReturnWidgetContainer;
