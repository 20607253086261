import { i18n } from 'utils/i18n';

const defaultFilters = [
  {
    keyPath: 'filters.CLASS.filterCode',
    type: 'checkbox',
  },
  {
    keyPath: 'filters.PASSENGERS.filterCode',
    type: 'checkbox',
    // eslint-disable-next-line
    labelHandler: (label, value, lowestCarFilterPrices) => i18n('vehicle_filters_and_up_passengers', [label]),
  },
  {
    keyPath: 'filters.BAGS.filterCode',
    isRadio: true,
    type: 'select',
    optionDecorateKey: 'vehicle_filters_and_up_passengers',
  },
  {
    keyPath: 'filters.TRANSMISSION.filterCode',
    type: 'select',
    isSingleSelect: true,
  },
  {
    keyPath: 'filters.DRIVE.filterCode',
    type: 'checkbox',
    optionDecorateKey: 'vehicle_filters_and_drive',
  },
];

export const filters = defaultFilters;
