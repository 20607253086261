import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Checkbox from 'components/Form/Checkbox';
import Button from 'components/Button';
import ANALYTICS from 'constants/analytics';
import THEMES from 'constants/themes';
import { i18n } from 'utils/i18n';
import { capitalizeAll } from 'utils/string';
import { Form } from 'react-final-form';
import { utils as globalUtils } from '@ehi/global-marketing-interface';
import utils from 'utils';

const VehicleClassFilterModalContent = (props) => {
  const [vehicleCategories, setVehicleCategories] = useState();

  useEffect(() => {
    setVehicleCategories(props.vehicleCategories);
  }, [props.vehicleCategories]);

  const onSubmit = () => {};

  const handleSelectAll = (index, category) => {
    props.handleSetVehicleState(vehicleCategories, index);
    utils.analytics.interaction(
      ANALYTICS.FILTER,
      utils.i18n('search_vehicle_class'),
      `${capitalizeAll(ANALYTICS.SELECT_ALL)} ${category}`
    );
  };

  const handleCheckboxChange = (value, carIndex, keyIndex) => (e, input, checked) => {
    const vehicleData = vehicleCategories;
    const vehicles = [...vehicleData[carIndex].vehicles];

    // updating select all for vehicle class false when user makes changes to his select all classes
    if (vehicleData[carIndex].carClassChecked) {
      vehicleData[carIndex].carClassChecked = false;
    }

    vehicles[keyIndex].checked = checked ? true : checked;
    vehicleData[carIndex] = { ...vehicleData[carIndex], vehicles };
    props.handleCheckboxChange(vehicleData);
    const classesSelected = [...vehicles];
    classesSelected.filter((item) => item.checked).map((vehicle) => vehicle.name);
    utils.analytics.interaction(ANALYTICS.FILTER, `${value}`, checked ? `Added ${value}` : `Removed ${value}`);
  };

  return (
    <div className='vehicle-filters'>
      {!!vehicleCategories &&
        vehicleCategories.map((category, index) => {
          const { vehicles } = category;
          if (globalUtils.isArrayNotEmpty(vehicles)) {
            return (
              <Form key={index} onSubmit={onSubmit}>
                {({ handleSubmit }) => (
                  <form className='searchby-vehicle-modal__form' onSubmit={handleSubmit}>
                    <div className='vehicle-filters__header-row-container'>
                      <span className='vehicle-filters__header-row-container--label'>{category.label}</span>
                      <Button
                        plain
                        className='vehicle-filters__header-row-container--select-all-button'
                        type='button'
                        onClick={() => handleSelectAll(index, category.label)}
                        aria-label={i18n('common_select_all')}
                      >
                        {' '}
                        {utils.i18n('common_select_all')}
                      </Button>
                    </div>
                    <div className='vehicle-filters__grid'>
                      {vehicles?.map(({ checked, name }, keyIndex) => (
                        <div key={keyIndex} className='vehicle-filters__grid-item'>
                          <Checkbox
                            keyIndex={keyIndex}
                            carIndex={index}
                            checked={checked}
                            label={name}
                            value={name}
                            name='checkbox_select'
                            className='global-gateway-modal__checkbox'
                            theme={THEMES.LIGHT}
                            onChange={handleCheckboxChange(name, index, keyIndex)}
                          />
                        </div>
                      ))}
                    </div>
                  </form>
                )}
              </Form>
            );
          }
          return null;
        })}
    </div>
  );
};

VehicleClassFilterModalContent.propTypes = {
  vehicleCategories: PropTypes.array,
  setvehicleCategories: PropTypes.func,
};

export default VehicleClassFilterModalContent;
