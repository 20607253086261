import React from 'react';
import { useSelector } from 'react-redux';
import useActions from 'hooks/useActions';
import utils from 'utils';
import ReservationLookupTemplate from 'components/ReservationLookup/ReservationLookupTemplate';
import { getRentalAgreement } from '../../redux/selectors';
import { verifyByConfirmationNumber } from '../../redux/actions';
import VerifyUI from './VerifyUI';

const VerifyContainer = (props) => {
  const agreementData = useSelector(getRentalAgreement);
  const searchAction = useActions(verifyByConfirmationNumber);

  const { phone, email_address, first_name, last_name } = agreementData?.renter || {};
  const tripIdentifier = agreementData?.rental_agreement_identifier;
  const { phone_country_code, phone_number } = phone || {};

  const phoneCountryCode = phone_country_code;
  const phoneNumber = phone_number;
  const email = email_address;
  const firstName = first_name;
  const lastName = last_name;

  const titleText = utils.i18n('lets_confirm_its_you');
  const shortText = utils.i18n('lets_confirm_send_link_to_device');

  return (
    <ReservationLookupTemplate titleText={titleText} shortDesc={shortText}>
      <div className='rental-lookup rental-lookup__verify'>
        <VerifyUI
          {...{
            email,
            phoneCountryCode,
            phoneNumber,
            firstName,
            lastName,
            tripIdentifier,
            agreementData,
            searchAction,
            ...props,
          }}
        />
      </div>
    </ReservationLookupTemplate>
  );
};

export default VerifyContainer;
