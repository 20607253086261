import React, { Component } from 'react';
import utils from 'utils';
import ResponsiveListbox from 'components/Form/ResponsiveListbox';
import propTypes from './CountrySelectorPropTypes';

class CountrySelector extends Component {
  state = {
    countries: [],
    setResponsiveListBox: false,
  };

  static propTypes = propTypes;

  handleChange = e => {
    if (e) {
      window.location = e;
    }
    console.log(e);
  };

  componentDidMount() {
    const { countries } = this.props;
    const sortedCountries = utils.gmi.sortArrayOfObjectsByKey(countries, 'countryTitle');
    this.setState({
      countries: sortedCountries,
    });
  }

  handleResponsiveListBox = () => {
    this.setState({
      setResponsiveListBox: !this.state.setResponsiveListBox,
    });
  };

  render() {
    const { currentPageTitle } = this.props;
    const { countries } = this.state;
    return (
      <div>
        <ResponsiveListbox
          name='select_country'
          label={utils.i18n('country_selector')}
          onChange={this.handleChange}
          value={location.pathname}
          options={countries && countries.map(option => ({ value: option.countryPath, label: option.countryTitle }))}
          currentPageTitle={currentPageTitle}
          countrySelector={true}
        />
      </div>
    );
  }
}
export default CountrySelector;
