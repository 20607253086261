import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import useActions from 'hooks/useActions';
import {
  getReturnDateAndTime,
  getRentalReturnLocation,
  getRentalPriceSummary,
  selectedPaymentMethodSelector,
  rentalCouponsSelector,
  rentalFormattedPoliciesSelector,
  selectedExtras,
  selectedBundleWithExtrasSelector,
  extrasWithoutBundlesSelector,
  numberOfExtrasSelector,
  selectTotalSummaryList,
  getContractDetails,
  getRentalPayments,
  getRentalMileageInfo,
  getRentalPaymentLineItems,
  getShowCorporateCoverage,
} from 'selectors/rentalTicketSelectors';
import { breakpointSelector } from 'selectors/breakpoint';
import { getPaymentLines } from 'utils/prices';
import { commit } from 'actions/modifyRental';
import ConfirmModifyCommit from './ConfirmModifyCommit';

const ConfirmModifyCommitContainer = (props) => {
  const policies = useSelector(rentalFormattedPoliciesSelector);
  const selectedPaymentMethod = useSelector(selectedPaymentMethodSelector);
  const shouldShowPolicyComponent = policies.length !== 0;
  const returnDateAndTime = useSelector(getReturnDateAndTime);
  const returnLocation = useSelector(getRentalReturnLocation);
  const priceSummary = useSelector(getRentalPriceSummary);
  const totalSummaryItems = useSelector(selectTotalSummaryList);
  const extras = useSelector(selectedExtras);
  const selectedBundleWithExtras = useSelector(selectedBundleWithExtrasSelector);
  const extrasWithoutBundles = useSelector(extrasWithoutBundlesSelector);
  const numberOfExtrasSelected = useSelector(numberOfExtrasSelector);
  const coupons = useSelector(rentalCouponsSelector);
  const commitRentalAction = useActions(commit);
  const contractDetails = useSelector(getContractDetails);
  const breakpoint = useSelector(breakpointSelector);
  const payments = useSelector(getRentalPayments);
  const mileageInfo = useSelector(getRentalMileageInfo);
  const paymentLineItems = useSelector(getRentalPaymentLineItems);
  const showCorporateCoverage = useSelector(getShowCorporateCoverage);

  const { bill_to_subtotals } = priceSummary;

  const renterPaymentLines = useMemo(
    () => getPaymentLines(paymentLineItems, mileageInfo, priceSummary?.renter_subtotal_view, 'RENTER'),
    [JSON.stringify(paymentLineItems), JSON.stringify(payments)]
  );

  const contractNumber = contractDetails?.contract_number;
  const totalViewContract = bill_to_subtotals?.find((item) => item.customer_number === contractNumber);

  const estimatedBillToTotalView = totalViewContract?.total_currency || bill_to_subtotals?.[0]?.total_currency;
  const billToPaymentLines = useMemo(
    () => getPaymentLines(paymentLineItems, mileageInfo, estimatedBillToTotalView, 'BILLTO'),
    [JSON.stringify(paymentLineItems), JSON.stringify(payments)]
  );

  const history = useHistory();

  return (
    <ConfirmModifyCommit
      {...{
        policies,
        selectedPaymentMethod,
        shouldShowPolicyComponent,
        returnTime: returnDateAndTime,
        returnLocation,
        priceSummary,
        extras,
        selectedBundleWithExtras,
        extrasWithoutBundles,
        numberOfExtrasSelected,
        coupons,
        commitRentalAction,
        history,
        totalSummaryItems,
        showCorporateCoverage,
        contractDetails,
        breakpoint,
        renterPaymentLines,
        billToPaymentLines,
        ...props,
      }}
    />
  );
};

export default ConfirmModifyCommitContainer;
