import { connect } from 'react-redux';
import Storage from 'utils/storageManager';
import { resolveBranchURL } from 'actions/locationSearch';
import { getSupportContactInfo } from 'actions/content';
import { retrieveAndCheckEligibility, rentalRetrieveTicketNumber } from 'actions/modifyRental';
import { breakpointSelector } from 'selectors/breakpoint';
import MODAL from 'constants/modals';
import RESERVATIONS from 'constants/reservations';
import { SESSION_STORAGE } from 'constants/session';
import { openModal } from "actions/modal/openModal";
import { openModalWithProps } from "actions/modal/openModalWithProps";
import TripCard from './TripCard';

const mapStateToProps = (state, ownProps) => ({
  breakpoint: breakpointSelector(state),
  afterHoursPolicies: ownProps.return_location?.policies?.find(
    (policy) => policy.description === RESERVATIONS.POLICY_TYPES.AFTER_HOURS_SERVICE
  )?.policy_text,

  currentRentalDetails: Storage.SessionStorage.get(SESSION_STORAGE.RENTAL_TICKET_DETAILS, true) || {},
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  redirectToLocationDetailsPage: () => dispatch(resolveBranchURL(ownProps.locationDetailsID)),
  retrieveAndCheckEligibility: (ticketNumber) => dispatch(retrieveAndCheckEligibility(ticketNumber)),
  rentalRetrieveTicketNumber: (ticketNumber, redirect) => dispatch(rentalRetrieveTicketNumber(ticketNumber, redirect)),
  openExtendRentalModal: (country_code) => {
    dispatch(getSupportContactInfo(country_code));
    return dispatch(openModalWithProps(MODAL.EXTEND_RENTAL_INFORMATION_MODAL, { country_code }));
  },
  openAfterHoursModal: (return_directions, afterHoursPolicies) =>
    dispatch(
      openModalWithProps(MODAL.AFTER_HOURS_RETURN_INSTRUCTIONS_MODAL, { return_directions, afterHoursPolicies })
    ),
  openReturnDirectionsModal: (return_directions) =>
    dispatch(openModalWithProps(MODAL.RETURN_DIRECTIONS_MODAL, { return_directions })),

  openLearnMoreAboutRoadsideAssistance: () => dispatch(openModal(MODAL.LEARN_MORE_ABOUT_ROADSIDE_ASSISTANCE_MODAL)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TripCard);
