import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import utils from 'utils';
import useCountryFieldsConfig from 'hooks/useCountryFieldsConfig';
import useSupportPhoneNumber from 'hooks/useSupportPhoneNumber';
import ServiceErrors from 'components/ServiceErrors';
import Form from 'components/Form/Form';
import RequiredFieldsIndicator from 'components/RequiredFieldsIndicator';
import Button from 'components/Button';
import DateFieldset from 'components/Form/DateFieldset';
import TextField from 'components/Form/TextField';
import SelectField from 'components/Form/SelectField';
import PhoneNumberField from 'components/Form/PhoneNumberField';
import ToolTip from 'components/ToolTip';
import Anchor from 'components/Anchor';

const domainCountryCode = utils.config.getDomainCountry();

/**
 * DriverProfileForm Component
 * Form for editing a driver profile, such as in OCI or Additional Driver flows
 *
 * @param {object} props - React Props
 * @param {func} props.renderFooter - function used to render Modal footer, takes the onSubmit handler as first param
 * @param {func} props.submitHandler - handler to run on successful form submit
 * @param {object} props.initialValues - data used to pre-populate form
 * @param {string} props.initialCountryCode - used if country code has been preselected, defaults to domainCountryCode
 * @param {array} props.errorMessages - service error messages
 *
 * @return {JSX} DriverProfileForm jsx component
 */
const DriverProfileForm = ({
  renderFooter,
  submitHandler,
  initialValues,
  initialCountryCode = domainCountryCode,
  includeNameFields,
  errorMessages,
  handleSelectedCountry,
}) => {
  const licenseCountryCodeFieldName = 'license_info.country_code';
  const addressCountryCodeFieldName = 'address_info.country_code';
  const [countryFieldsMap, setCountryFieldMapItem] = useState({});
  const [countryConfigMap, setCountryConfigMapItem] = useState({});
  const [addressExpanded, setAddressExpanded] = useState(false);

  const { selectedCountry, setSelectedCountry, countryConfig, countries, statesOrProvinces } =
    useCountryFieldsConfig(initialCountryCode);

  const {
    selectedCountry: selectedAddressCountry,
    setSelectedCountry: setSelectedAddressCountry,
    countryConfig: addressCountryConfiguration,
    countries: addressCountries,
    statesOrProvinces: addressStatesOrProvices,
  } = useCountryFieldsConfig(initialValues.address_info.country_code);

  const { shouldShowIssueDate, shouldShowExpirationDate, shouldShowBothIssueAndExpirationDate } = countryConfig;

  const alamoInsidersSupportNumber = useSupportPhoneNumber();

  const setCountry = (type, countryCode, fieldName) => {
    const countryObject =
      type === 'address'
        ? utils.locations.findCountryCode(addressCountries, countryCode)
        : utils.locations.findCountryCode(countries, countryCode);
    setCountryFieldMapItem({
      ...countryFieldsMap,
      [fieldName]: countryObject?.country_code,
    });
    type === 'address' ? setSelectedAddressCountry(countryObject) : setSelectedCountry(countryObject);
  };

  const setSelectCountry =
    (type) =>
    ({ target }) => {
      setCountry(type, target?.value, target?.name);
      handleSelectedCountry?.(target?.value);
    };

  const toggleAddressLine2 = () => {
    setAddressExpanded(!addressExpanded);
  };

  useEffect(() => {
    const hasCountryFields = Object.keys(countryFieldsMap || {}).length;
    const hasSelectedCountryFields = Object.keys(selectedCountry || {}).length > 0;

    if (!hasCountryFields && hasSelectedCountryFields) {
      const selectedCountryCode = selectedCountry?.country_code;
      const selectedAddressCountryCode = selectedAddressCountry?.country_code;
      setCountryFieldMapItem({
        ...countryFieldsMap,
        [licenseCountryCodeFieldName]: selectedCountryCode,
        [addressCountryCodeFieldName]: selectedAddressCountryCode,
      });
    }
  }, [selectedCountry?.country_code]);

  useEffect(() => {
    const selectedCountryCode = selectedCountry?.country_code;
    const selectedAddressCountryCode = selectedAddressCountry?.country_code;

    if (!selectedCountryCode) {
      return;
    }

    setCountryConfigMapItem({
      ...countryConfigMap,
      [selectedCountryCode]: countryConfig,
      [selectedAddressCountryCode]: addressCountryConfiguration,
    });
  }, [selectedCountry?.country_code, selectedAddressCountry?.country_code, Object.keys(statesOrProvinces).length]);

  const licenseCountryCode = countryFieldsMap[licenseCountryCodeFieldName];
  const addressCountryCode = countryFieldsMap[addressCountryCodeFieldName];
  const licenseCountryConfig = countryConfigMap[licenseCountryCode];
  const addressCountryConfig = countryConfigMap[addressCountryCode];

  return (
    <Form
      onSubmit={submitHandler}
      initialValues={initialValues}
      validate={(values) =>
        utils.date.validateIssueAndExpiration(
          values,
          shouldShowBothIssueAndExpirationDate,
          shouldShowIssueDate,
          shouldShowExpirationDate
        )
      }
      subscription={{ submitFailed: true, values: true }}
    >
      {({ handleSubmit, form }) => (
        <>
          <RequiredFieldsIndicator customClass='in-res-oci-modal__spaced-required-indicator' />
          <ServiceErrors error={errorMessages} />
          <form className='check-in-flow__form component-theme--light' noValidate onSubmit={handleSubmit}>
            <div className='check-in-flow__form__section-title'>
              <h3>{utils.i18n('check_in_driver_lookup_form_title')}</h3>
            </div>

            {includeNameFields && (
              <div className='check-in-flow__form__field-row'>
                <TextField
                  className='check-in-flow__form__field-row-first-name'
                  id='license_info.first_name'
                  name='license_info.first_name'
                  label={utils.i18n('common_first_name')}
                  fill
                  required
                  autoComplete='do-not-autocomplete'
                  tooltip={utils.i18n('additional_driver_tooltip')}
                />
                <TextField
                  id='license_info.last_name'
                  name='license_info.last_name'
                  label={utils.i18n('common_last_name')}
                  fill
                  required
                  autoComplete='do-not-autocomplete'
                />
              </div>
            )}

            <div className='check-in-flow__form__field-row'>
              {!!countries?.length && (
                <SelectField
                  id={licenseCountryCodeFieldName}
                  name={licenseCountryCodeFieldName}
                  label={utils.i18n('my_profile_drivers_license_issuing_country')}
                  onChange={setSelectCountry('license')}
                  options={countries}
                  valueKey='country_code'
                  labelKey='country_name'
                  required
                  includeHiddenOption
                />
              )}
              {licenseCountryConfig?.shouldShowSubdivisionField &&
                !licenseCountryConfig?.shouldShowIssuingAuthorityField && (
                  <SelectField
                    id='license_info.country_subdivision_code'
                    name='license_info.country_subdivision_code'
                    label={utils.i18n('my_profile_drivers_license_state_province')}
                    options={statesOrProvinces[licenseCountryCode]}
                    valueKey='country_subdivision_code'
                    labelKey='country_subdivision_name'
                    required
                    includeHiddenOption={!initialValues?.license_info.country_subdivision_code}
                    maskedOption={initialValues?.license_info.country_subdivision_code}
                  />
                )}
              {licenseCountryConfig?.shouldShowIssuingAuthorityField &&
                !!licenseCountryConfig?.issuingAuthorities?.length && (
                  <SelectField
                    id='license_info.issuing_authority'
                    name='license_info.issuing_authority'
                    label={utils.i18n('my_profile_drivers_license_issuing_authority')}
                    options={licenseCountryConfig.issuingAuthorities}
                    valueKey='country_subdivision_code'
                    labelKey='country_subdivision_name'
                    required
                    includeHiddenOption={!initialValues?.license_info.issuing_authority}
                    maskedOption={initialValues?.license_info.issuing_authority}
                  />
                )}
            </div>
            <div className='check-in-flow__form__field-row'>
              <TextField
                id='license_info.license_number'
                name='license_info.license_number'
                label={utils.i18n('my_profile_drivers_license_number')}
                fill
                required
                autoComplete='license_number'
                initialValueButton={initialValues?.license_info.license_number}
              />
            </div>

            {shouldShowBothIssueAndExpirationDate && (
              <div className='check-in-flow__date-info'>
                <h4 className='check-in-flow__date-info__title'>
                  {utils.i18n('check_in_driver_license_drivers_date')}
                </h4>
                <p>{utils.i18n('check_in_driver_license_drivers_date_description')}</p>
              </div>
            )}
            {shouldShowExpirationDate && (
              <div
                className={cn('check-in-flow__form__date-row', {
                  'check-in-flow__form__date-row-no-padding': shouldShowBothIssueAndExpirationDate,
                })}
              >
                <DateFieldset
                  id='license_expiration_date'
                  label={utils.i18n('my_profile_drivers_license_expiration_date')}
                  countryCode={licenseCountryCode}
                  initialValue={initialValues?.license_info.license_expiration_date}
                  isRequired={!shouldShowBothIssueAndExpirationDate}
                  validate={false}
                  form={form}
                />
              </div>
            )}
            {shouldShowIssueDate && (
              <div className='check-in-flow__form__date-row'>
                <DateFieldset
                  id='license_issue_date'
                  label={utils.i18n('my_profile_drivers_license_issue_date')}
                  countryCode={licenseCountryCode}
                  initialValue={initialValues.license_info.license_issue_date}
                  isRequired={!shouldShowBothIssueAndExpirationDate}
                  validate={false}
                  form={form}
                />
              </div>
            )}

            {shouldShowBothIssueAndExpirationDate && utils.date.isExpirationAndIssueFormStateNotValid(form) && (
              <span className='check-in-flow__form__date-error-message'>
                {utils.i18n('my_profile_drivers_license_expiration_or_issue_date_required')}
              </span>
            )}
            {shouldShowBothIssueAndExpirationDate && <hr className='check-in-flow__divider' />}

            {initialValues?.birth_date ? (
              <div className='check-in-flow__form__field-row check-in-flow__form__date-field'>
                <div className='check-in-flow__form__date-field__label'>
                  {utils.i18n('my_profile_drivers_license_date_of_birth')}
                  {` (${utils.config.getDateTimeFormat('l')})`}
                  <ToolTip placement='top-start'>
                    <span>
                      {utils.i18n(
                        'my_profile_drivers_license_dob_tooltip',
                        [
                          <Anchor
                            key='0'
                            className='link'
                            href={`tel:${alamoInsidersSupportNumber}`}
                            aria-label={alamoInsidersSupportNumber}
                          >
                            {alamoInsidersSupportNumber}
                          </Anchor>,
                        ],
                        { jsx: true }
                      )}
                    </span>
                  </ToolTip>
                </div>
                <p>{utils.gmi.getMaskedGBODate(initialValues.birth_date)}</p>
              </div>
            ) : (
              <div className='check-in-flow__form__date-row'>
                <DateFieldset
                  id='birth_date'
                  label={utils.i18n('my_profile_drivers_license_date_of_birth')}
                  countryCode={licenseCountryCode}
                />
              </div>
            )}

            <div className='check-in-flow__form__section'>
              <div className='check-in-flow__form__section-title'>
                <h3>{utils.i18n('check_in_contact_info_form_title')}</h3>
              </div>

              <div className='check-in-flow__form__field-row'>
                <TextField
                  name='contact_info.email'
                  label={utils.i18n('common_email')}
                  required
                  fill
                  initialValueButton={initialValues?.contact_info.email}
                  validations={[utils.fieldValidation.email]}
                />
              </div>
              <div className='check-in-flow__form__field-row'>
                <PhoneNumberField
                  name='contact_info.phone_set[0].phone_number'
                  label={utils.i18n('my_profile_contact_information_phone')}
                  required
                  fill
                  initialValueButton={initialValues?.contact_info?.phone_set?.[0]?.phone_number}
                  validations={[utils.fieldValidation.phone, utils.fieldValidation.checkPhoneCode]}
                />
              </div>
              <p className='check-in-flow__form__helper-text'>
                {utils.i18n('check_in_contact_info_form_phone_helper')}
              </p>

              <TextField
                name='address_info.address_line_1'
                label={utils.i18n('my_profile_edit_contact_information_address_line_1_label')}
                className='check-in-flow__form__full-width-field'
                required
                fill
                initialValueButton={initialValues?.address_info?.address_line_1}
              />

              {addressExpanded ? (
                <TextField
                  name='address_info.address_line_2'
                  label={utils.i18n('my_profile_edit_contact_information_address_line_2_label')}
                  className='check-in-flow__form__full-width-field'
                  fill
                  initialValueButton={initialValues?.address_info?.address_line_2}
                />
              ) : (
                <Button className='check-in-flow__form__expand-button' onClick={toggleAddressLine2} link>
                  {utils.i18n('my_profile_edit_contact_information_address_line_2_button')}
                </Button>
              )}

              <div className='check-in-flow__form__field-row'>
                {!!addressCountries?.length && (
                  <SelectField
                    id={addressCountryCodeFieldName}
                    name={addressCountryCodeFieldName}
                    label={utils.i18n('my_profile_edit_contact_information_country_label')}
                    onChange={setSelectCountry('address')}
                    options={addressCountries}
                    valueKey='country_code'
                    labelKey='country_name'
                    disabled={!addressCountries?.length}
                    required
                    includeHiddenOption
                  />
                )}
              </div>

              <div className='check-in-flow__form__field-row'>
                <TextField
                  name='address_info.city'
                  label={utils.i18n('my_profile_edit_contact_information_city_label')}
                  required
                  fill
                  initialValueButton={initialValues?.address_info?.city}
                />

                {addressCountryConfig?.shouldShowSubdivisionField && (
                  <SelectField
                    id='address_info.country_subdivision_code'
                    name='address_info.country_subdivision_code'
                    label={utils.i18n('my_profile_edit_contact_information_state_province_label')}
                    options={addressStatesOrProvices[addressCountryCode]}
                    valueKey='country_subdivision_code'
                    labelKey='country_subdivision_name'
                    required
                    includeHiddenOption={!initialValues?.address_info?.country_subdivision_code}
                    maskedOption={initialValues?.address_info?.country_subdivision_code}
                  />
                )}
              </div>

              <div className='check-in-flow__form__field-row'>
                <TextField
                  name='address_info.postal'
                  label={utils.i18n('my_profile_edit_contact_information_postal_code_label')}
                  required
                  fill
                  initialValueButton={initialValues?.address_info?.postal}
                />
              </div>
            </div>

            {renderFooter(handleSubmit)}
          </form>
        </>
      )}
    </Form>
  );
};

DriverProfileForm.propTypes = {
  renderFooter: PropTypes.func,
  submitHandler: PropTypes.func,
  initialValues: PropTypes.object,
  initialCountryCode: PropTypes.string,
  errorMessages: PropTypes.array,
  includeNameFields: PropTypes.bool,
  handleSelectedCountry: PropTypes.func,
};

export default DriverProfileForm;
