import React, { useEffect, useState } from 'react';
import utils from 'utils';
import { useSelector } from 'react-redux';
import WINDOW_OBJECT_KEYS from 'constants/windowObjectKeys';
import useActions from 'hooks/useActions';
import { retrieveSubscription, optOutExtras } from 'actions/subscriptions';
import { subscriptionDetailsSelector } from 'selectors/profileSelectors';
import ServiceErrors from 'components/ServiceErrors';
import EmailUnsubscribeConfirmation from './EmailUnsubscribeConfirmation';
import EmailUnsubscribeSuccess from './EmailUnsubscribeSuccess';
import './styles/email-unsubscribe.scss';

/**
 * EmailUnsubscribe
 *
 * @return {JSX}
 */
const EmailUnsubscribe = () => {
  const { unsubid } = utils.url.queryObject();
  const [isUnsubConfirmation, setIsUnsubConfirmation] = useState(true);
  const subscriptionDetails = useSelector(subscriptionDetailsSelector);
  const isAuthorMode = utils.config.getIsAuthorModeEnabled();
  const retrieveSub = useActions(retrieveSubscription);
  const handleUnsub = useActions(optOutExtras);

  useEffect(() => {
    if (unsubid) {
      retrieveSub({ id: unsubid });
    } else if (!isAuthorMode) {
      utils.loadingRedirect(WINDOW_OBJECT_KEYS.EMAIL_SPECIALS);
    }
  }, []);

  const onUnsubscribe = () => {
    handleUnsub(subscriptionDetails).then(utils.runOnSuccessResponse(setIsUnsubConfirmation));
  };

  return (
    <>
      <ServiceErrors />
      <h1 className='email-unsubscribe__title'>
        {utils.i18n(isUnsubConfirmation ? 'email_unsub_confirm_title' : 'email_unsub_success_title')}
      </h1>
      {isUnsubConfirmation ? (
        <EmailUnsubscribeConfirmation email={subscriptionDetails?.email} onUnsubscribe={onUnsubscribe} />
      ) : (
        <EmailUnsubscribeSuccess email={subscriptionDetails?.email} />
      )}
    </>
  );
};

export default EmailUnsubscribe;
