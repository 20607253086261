import React from 'react';
import utils from 'utils';
import { useSelector } from 'react-redux';
import useActions from 'hooks/useActions';
import ANALYTICS from 'constants/analytics';
import { MODAL, MODAL_THEMES, MODAL_BUTTON_TYPES } from 'constants';
import Modal from 'components/Modal';
import { ModalFooter } from 'components/Modal/ModalFooter';
import { euYoungDriverFeeSelector } from 'selectors/reservationFlowSelectors';
import { modifyReservationStart } from 'actions/reservation/modifyReservationStart';
import { addAdditionalDriverByName } from 'actions/reservation/addAdditionalDriverByName';
import { removeAdditionalDriver } from 'actions/reservation/removeAdditionalDriver';

const EuYoungDriverFeeModal = () => {
  const modifyStart = useActions(modifyReservationStart);
  const removeDriver = useActions(removeAdditionalDriver);
  const addDriver = useActions(addAdditionalDriverByName);
  const { youngDriver } = useSelector(euYoungDriverFeeSelector);

  const handleYoungDriverFee = () => {
    // Before redirecting the modal to res flow, we have to delete the existing driver and
    // add the driver first name and last name to requesting to  enter the details again for EU domains.
    utils.dom.loadingOverlay(true);
    removeDriver(youngDriver).then((resp) => {
      if (!resp.messages) {
        addDriver({ firstName: youngDriver.first_name, lastName: youngDriver.last_name }).then((response) => {
          utils.dom.loadingOverlay(false);
          if (!response.messages) {
            modifyStart();
          }
        });
      } else {
        utils.dom.loadingOverlay(false);
      }
    });
  };

  return (
    <Modal
      modalKey={MODAL.EU_YOUNG_DRIVER_FEE_MODAL}
      header={utils.i18n('eu_young_driver_fee_modal_title')}
      theme={MODAL_THEMES.WHITE}
    >
      {({ fee }) => (
        <>
          <p className='modal-themed__paragraph'>{utils.i18n('eu_young_driver_fee_modal_copy_1', [fee])}</p>
          <p className='modal-themed__paragraph'>{utils.i18n('eu_young_driver_fee_modal_copy_2')}</p>
          <p className='modal-themed__paragraph'>{utils.i18n('eu_young_driver_fee_modal_copy_3')}</p>
          <ModalFooter
            buttons={[
              {
                type: MODAL_BUTTON_TYPES.DECLINE,
                label: utils.i18n('eu_young_driver_fee_modal_decline'),
                largeButton: true,
                analyticsKey: ANALYTICS.ADD_LATER,
              },
              {
                type: MODAL_BUTTON_TYPES.ACCEPT,
                label: utils.i18n('eu_young_driver_fee_modal_accept'),
                handler: handleYoungDriverFee,
                largeButton: true,
                analyticsHandler: () =>
                  utils.analytics.interaction(
                    ANALYTICS.MODAL,
                    utils.analytics.prepareKey(MODAL.EU_YOUNG_DRIVER_FEE_MODAL),
                    ANALYTICS.CONTINUE
                  ),
              },
            ]}
          />
        </>
      )}
    </Modal>
  );
};

export default EuYoungDriverFeeModal;
