import React, { Component } from 'react';
import PropTypes from 'prop-types';
import utils from 'utils';
import { getFormattedContractRestrictionDates } from 'utils/reservation';
import { formatDateTimeStampAndReturnDateTimeObj } from 'utils/date';
import { showEveryDayLowRatesBanner } from 'utils/banners';
import { ANALYTICS, GMI_SERVICE_PATHS, RESERVATIONS, WINDOW_OBJECT_KEYS } from 'constants';
import Button from 'components/Button';
import Anchor from 'components/Anchor';
import GenericNotification from 'components/GenericNotification';
import LocationDateTimeFieldGroup from 'components/BookingWidget/LocationDateTimeFieldGroup';
import LocationDateTimeFormSpy from 'components/BookingWidget/LocationDateTimeFieldGroup/LocationDateTimeFormSpy';
import Form from 'components/Form/Form';
import ServiceErrors from 'components/ServiceErrors';
import ProgressBarDrawerWrapper from '../ProgressBarDrawerWrapper';

const { EXCHANGE_TYPE_PICKUP, EXCHANGE_TYPE_RETURN } = RESERVATIONS;

const validate = (values) => {
  const errors = {};

  if (!values.pickupDate) {
    errors.pickupDate = utils.i18n('bw_pickup_date_submit_error');
  }
  if (!values.pickupTime) {
    errors.pickupTime = utils.i18n('bw_pickup_time_submit_error');
  }
  if (!values.returnDate) {
    errors.returnDate = utils.i18n('bw_return_date_submit_error');
  }
  if (!values.returnTime) {
    errors.returnTime = utils.i18n('bw_return_time_submit_error');
  }

  return errors;
};
class ProgressBarDatesTimesDrawer extends Component {
  formId = utils.dom.uuid();

  state = {
    didInitiate: false,
    restrictedDate: false,
  };

  // LMS and Plan Ahead Specials Variables
  contractRestrictions = this.props.contractDetails?.restrictions;

  formattedContractRestrictions =
    this.contractRestrictions &&
    getFormattedContractRestrictionDates(this.contractRestrictions, this.props.isPlanAheadSpecials);

  // Show everday low rate banner according to the CID restrictions
  shouldDisplayEveryDayLowRatesBanner = (type, date) => {
    const {
      values: { pickupDate, returnDate },
    } = this.props;

    const restrictedDate = showEveryDayLowRatesBanner(
      type,
      date,
      pickupDate,
      returnDate,
      this.formattedContractRestrictions
    );

    this.setState({ restrictedDate });
  };

  handleDateChange = (exchangeType) => (date) => {
    const { clearDate, onDateChange, values, cleanMessages } = this.props;
    const { pickupDate, returnDate } = values;

    const formattedDate = utils.gmi.getDateTimeObjFromTs(date).format('YYYY-MM-DD');
    let expectedExchangeType = exchangeType;

    if (exchangeType === EXCHANGE_TYPE_PICKUP && utils.gmi.getDateTimeObjFromTs(returnDate).isBefore(formattedDate)) {
      clearDate(EXCHANGE_TYPE_RETURN);
    }

    if (exchangeType === EXCHANGE_TYPE_RETURN && utils.gmi.getDateTimeObjFromTs(pickupDate).isAfter(formattedDate)) {
      expectedExchangeType = EXCHANGE_TYPE_PICKUP;
      returnDate && clearDate(EXCHANGE_TYPE_RETURN);
    }

    this.contractRestrictions &&
      this.shouldDisplayEveryDayLowRatesBanner(exchangeType, formatDateTimeStampAndReturnDateTimeObj(date));

    onDateChange(formattedDate, expectedExchangeType);
    cleanMessages();
  };

  handleTimeChange = (exchangeType) => (time) => {
    this.props.onTimeChange(time, exchangeType);
  };

  handleSubmit = () => {
    const { dispatchSetUpdatedSoldOutDateTime, adjustLocationId } = this.props;

    this.props.onSubmit().then((response) => {
      if (!response.messages) {
        this.setState({ didInitiate: true }, () => {
          this.props.onCloseDrawer();
        });
      }
    });

    !!adjustLocationId && dispatchSetUpdatedSoldOutDateTime(true);
  };

  componentDidMount() {
    const {
      values: { pickupDate, returnDate, pickupTime, returnTime },
      setLocation,
      dateTimeInitiateRequestValues,
      initiateLocationModifiedSelector,
      pickupLocation,
      returnLocation,
      onDateChange,
      onTimeChange,
      partner_reward_program,
      setPartnerRewardProgram,
      setPartnerRewardProgramMemberId,
    } = this.props;
    let restrictedDate = false;

    // Show Everyday low rate banner if pickupDate or returnDate are outside the formattedContractRestrictions on first render
    const restrictedPickUpDate = showEveryDayLowRatesBanner(
      EXCHANGE_TYPE_PICKUP,
      null,
      pickupDate,
      returnDate,
      this.formattedContractRestrictions
    );
    const restrictedReturnDate = showEveryDayLowRatesBanner(
      EXCHANGE_TYPE_RETURN,
      null,
      pickupDate,
      returnDate,
      this.formattedContractRestrictions
    );
    restrictedDate = restrictedPickUpDate || restrictedReturnDate;
    this.setState({ restrictedDate });
    if (dateTimeInitiateRequestValues && initiateLocationModifiedSelector) {
      setLocation(pickupLocation, EXCHANGE_TYPE_PICKUP);
      setLocation(returnLocation, EXCHANGE_TYPE_RETURN);
      onDateChange(pickupDate, EXCHANGE_TYPE_PICKUP);
      onTimeChange(pickupTime, EXCHANGE_TYPE_PICKUP);
      onDateChange(returnDate, EXCHANGE_TYPE_RETURN);
      onTimeChange(returnTime, EXCHANGE_TYPE_RETURN);
    }
    if (partner_reward_program) {
      setPartnerRewardProgram(partner_reward_program);
      setPartnerRewardProgramMemberId(partner_reward_program?.member_id);
    } else {
      // By calling this action without parameters, this states will be unset and cleared.
      setPartnerRewardProgram();
      setPartnerRewardProgramMemberId();
    }
  }

  componentWillUnmount() {
    // reset gmi > ui, to match what's in gbo
    if (!this.state.didInitiate) {
      const {
        onDateChange,
        onTimeChange,
        values: { pickupDate, pickupTime, returnDate, returnTime },
      } = this.props;

      onDateChange(pickupDate, EXCHANGE_TYPE_PICKUP);
      onTimeChange(pickupTime, EXCHANGE_TYPE_PICKUP);
      onDateChange(returnDate, EXCHANGE_TYPE_RETURN);
      onTimeChange(returnTime, EXCHANGE_TYPE_RETURN);
    }
  }

  render() {
    const {
      values,
      pickupLocationId,
      pickupDateValue,
      pickupEligibility,
      returnLocationId,
      returnDateValue,
      returnEligibility,
      one_way_rental,
      label,
      hasSelectedVehicle,
      contractDetails,
      isLastMinuteOrPlanAheadSpecials,
    } = this.props;
    const { pickupDate, pickupTime, returnDate, returnTime } = values;
    const { restrictedDate } = this.state;

    const returnHomeAnchor = (
      <Anchor
        href={utils.config.getRedirectUrl(WINDOW_OBJECT_KEYS.HOME_PAGE_URL)}
        className='link link--text'
        data-dtm-track={utils.analytics.dtm(ANALYTICS.PROGRESS_BAR, ANALYTICS.UI_ALERT, ANALYTICS.RETURN_HOME)}
      >
        {utils.i18n('special_rates_not_available_banner_link_label')}
      </Anchor>
    );

    return (
      <ProgressBarDrawerWrapper>
        <div className='progress-bar__drawer'>
          <h2 className='progress-bar__drawer-title'>{utils.i18n(label)}</h2>
          <ServiceErrors statePath={GMI_SERVICE_PATHS.RESERVATIONS_INITIATE} />
          {contractDetails && isLastMinuteOrPlanAheadSpecials && restrictedDate && (
            <GenericNotification
              messageClass='generic-notification__notifications-on'
              title={utils.i18n('special_rates_not_available_banner_title')}
              message={utils.i18n('special_rates_not_available_banner_message', [returnHomeAnchor], {
                jsx: true,
              })}
              dtmTrack={utils.analytics.dtm(ANALYTICS.PROGRESS_BAR, ANALYTICS.UI_ALERT, ANALYTICS.INVALID_DATES)}
            />
          )}
          <Form onSubmit={this.handleSubmit} initialValues={values} validate={validate}>
            {({ form, handleSubmit }) => (
              <form className='progress-bar__dates-times-form' onSubmit={handleSubmit} autoComplete='off'>
                <div className='progress-bar__dates-times-fields'>
                  <LocationDateTimeFieldGroup
                    pickup_location_id={pickupLocationId}
                    return_location_id={returnLocationId}
                    pickupDate={pickupDate}
                    pickupDateValue={pickupDateValue}
                    pickupEligibility={pickupEligibility}
                    pickupTime={pickupTime}
                    returnDate={returnDate}
                    returnDateValue={returnDateValue}
                    returnEligibility={returnEligibility}
                    returnTime={returnTime}
                    one_way_rental={one_way_rental}
                    onDateChangeReturn={this.handleDateChange(EXCHANGE_TYPE_RETURN)}
                    onTimeChangeReturn={this.handleTimeChange(EXCHANGE_TYPE_RETURN)}
                    onDateChangePickUp={this.handleDateChange(EXCHANGE_TYPE_PICKUP)}
                    onTimeChangePickUp={this.handleTimeChange(EXCHANGE_TYPE_PICKUP)}
                    contractRestrictions={isLastMinuteOrPlanAheadSpecials && this.formattedContractRestrictions}
                  />
                </div>
                <Button
                  data-dtm-track={utils.analytics.dtm(ANALYTICS.UI_BUTTON, ANALYTICS.PROGRESS_BAR, 'update_date_time')}
                  type='submit'
                  className='progress-bar__drawer-submit'
                >
                  {utils.i18n('progress_bar_submit_value')}
                </Button>
                <LocationDateTimeFormSpy form={form} />
              </form>
            )}
          </Form>
          {hasSelectedVehicle && (
            <p className='progress-bar__drawer-help-text'>{utils.i18n('progress_bar_datestimes_help_text')}</p>
          )}
        </div>
      </ProgressBarDrawerWrapper>
    );
  }
}

ProgressBarDatesTimesDrawer.propTypes = {
  values: PropTypes.object,
  pickupEligibility: PropTypes.object,
  returnEligibility: PropTypes.object,
  onDateChange: PropTypes.func,
  onTimeChange: PropTypes.func,
  onSubmit: PropTypes.func,
  onUnmount: PropTypes.func,
  adjustLocationId: PropTypes.string,
  dispatchSetUpdatedSoldOutDateTime: PropTypes.func,
};

export default ProgressBarDatesTimesDrawer;
