import React, { Component } from 'react';
import utils from 'utils';
import { ANALYTICS, MODAL_BUTTON_TYPES } from 'constants';
import { ModalFooter } from 'components/Modal/ModalFooter';
import termsOfUseVanModalContentPropTypes from './termsOfUseVanModalContentPropTypes';

const { ACCEPT, DECLINE } = MODAL_BUTTON_TYPES;

class TermsOfUseVanModalContent extends Component {
  static propTypes = termsOfUseVanModalContentPropTypes;

  render() {
    const { description } = this.props;

    return (
      <>
        <p className='modal-themed__copy'>{utils.i18n('terms_use_van_model_main_title_sub_text')}</p>

        <div className='modal-themed__gbo-content' dangerouslySetInnerHTML={utils.sanitize(description)} />

        <ModalFooter
          buttons={[
            {
              type: DECLINE,
              label: utils.i18n('terms_use_van_model_decline'),
              analyticsValue: ANALYTICS.DECLINE,
            },
            {
              type: ACCEPT,
              label: utils.i18n('terms_use_van_model_accept'),
              analyticsValue: ANALYTICS.ACCEPT,
            },
          ]}
        />
      </>
    );
  }
}

export default TermsOfUseVanModalContent;
