import { actions, services } from '@ehi/global-marketing-interface';
import { gmiRequest } from 'actions/request';
import RECAPTCHA from 'constants/recaptcha';
import { LOOK_UP_BY_TYPE } from 'components/ViewModifyCancelApp/data/constants';
import RESERVATION_LOOKUP from 'constants/reservationLookup';

const { LOOK_UP_TYPE } = RESERVATION_LOOKUP;

/**
 * @name rentalByPhone

 * @returns { session, go_to } object
 */

export const onSubmitRentalByPhone = () => (dispatch) =>
  dispatch(
    gmiRequest({
      action: actions.request({
        service: services.gma.rentalTicket.rentalByPhone,
        data: {},
      }),
      loadingOverlay: true,
    })
  );

/**
 * @name rentalByEmail

 * @returns { session, go_to } object
 */

export const onSubmitRentalByEmail = () => (dispatch) =>
  dispatch(
    gmiRequest({
      action: actions.request({
        service: services.gma.rentalTicket.rentalByEmail,
      }),
      loadingOverlay: true,
    })
  );

/**
 * @name rentalByConfNumber

 * @returns { session, go_to } object
 */

export const onSubmitRentalByConfNumber = () => (dispatch) =>
  dispatch(
    gmiRequest({
      action: actions.request({
        service: services.gma.rentalTicket.rentalByConfNumber,
      }),
      loadingOverlay: true,
    })
  );

/**
 * Submit LookUpBy form data
 * @param {object} fields - Form fields data
 * @param {string} lookupType - Type of look up: phone/email/confNumber
 *
 */
export const onLookUpBySearch = (lookupType, vmcFormFields) => (dispatch) => {
  const payload = {
    first_name: vmcFormFields?.first_name,
    last_name: vmcFormFields?.last_name,
    ...(lookupType === LOOK_UP_BY_TYPE.PHONE && {
      phone: {
        phone_country_code: vmcFormFields?.phone?.phone_country_code,
        phone_number: vmcFormFields?.phone?.phone_number,
      },
    }),
    ...(lookupType === LOOK_UP_BY_TYPE.EMAIL && {
      email_address: vmcFormFields?.email,
    }),
    ...(lookupType === LOOK_UP_BY_TYPE.CONF_NUMBER && {
      trip_identifier: vmcFormFields?.trip_identifier,
    }),
  };

  const rentalServiceMap = {
    [LOOK_UP_BY_TYPE.PHONE]: services.gma.rentalTicket.searchByPhone,
    [LOOK_UP_BY_TYPE.EMAIL]: services.gma.rentalTicket.searchByEmail,
    [LOOK_UP_BY_TYPE.CONF_NUMBER]: services.gma.rentalTicket.searchByConfNumber,
  };

  return dispatch(
    gmiRequest({
      service: rentalServiceMap[lookupType],
      data: {
        ...payload,
      },
      opts: {
        actionName:
          lookupType === LOOK_UP_BY_TYPE.CONF_NUMBER
            ? RECAPTCHA.ACTION_NAMES.SEARCH_TRIPS
            : RECAPTCHA.ACTION_NAMES.SEND_DEEPLINK,
        actionType: RECAPTCHA.ACTION_TYPE_RECAPTCHA,
      },
      loadingOverlay: true,
    })
  );
};

/**
 * Submit LookUpBy form data
 * @param {object} fields - Form fields data
 * @param {string} lookupType - Type of look up: phone/email/confNumber
 *
 */
export const verifyByConfirmationNumber = (lookupType, vmcFormFields) => (dispatch) => {
  const payload = {
    first_name: vmcFormFields?.first_name,
    last_name: vmcFormFields?.last_name,
    trip_identifier: vmcFormFields?.trip_identifier,
    deeplink_delivery_option: lookupType,
  };

  return dispatch(
    gmiRequest({
      service: services.gma.rentalTicket.verifyByConfNumber,
      data: {
        ...payload,
      },
      opts: {
        actionName: RECAPTCHA.ACTION_NAMES.SEND_DEEPLINK,
        actionType: RECAPTCHA.ACTION_TYPE_RECAPTCHA,
      },
      loadingOverlay: true,
    })
  );
};

export const setCountrySelectedSessionState = (data) => (dispatch) =>
  dispatch({
    type: LOOK_UP_TYPE.COUNTRY_SELECTED,
    data,
  });
