import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import utils from 'utils';
import ANALYTICS from 'constants/analytics';
import GMI_SERVICE_PATHS from 'constants/gmiServicePaths';
import LOCATIONS from 'constants/locations';
import RESERVATIONS from 'constants/reservations';
import Form from 'components/Form/Form';
import Button from 'components/Button';
import LocationSearch from 'components/LocationSearch';
import ServiceErrors from 'components/ServiceErrors';
import ProgressBarDrawerWrapper from '../ProgressBarDrawerWrapper';

const { EXCHANGE_TYPE_PICKUP, EXCHANGE_TYPE_RETURN } = RESERVATIONS;

class ProgressBarLocationDrawer extends Component {
  formId = utils.dom.uuid();

  state = {
    isOneWay: this.props.initialValues.oneWayRental,
    didInitiate: false,
    initialValues: {
      pickupLocation: null,
      returnLocation: null,
    },
  };

  // Stores return location for toggle rehydration
  _returnLocation = (this.props.initialValues.oneWayRental && this.props.initialValues.returnLocation) || null;

  handleInitialValues = () => {
    const { initialValues, partner_reward_program, setPartnerRewardProgram, setPartnerRewardProgramMemberId } =
      this.props;
    this.setState({
      initialValues: { ...initialValues },
    });
    if (partner_reward_program) {
      setPartnerRewardProgram(partner_reward_program);
      setPartnerRewardProgramMemberId(partner_reward_program?.member_id);
    } else {
      // By calling this action without parameters, this states will be unset and cleared.
      setPartnerRewardProgram();
      setPartnerRewardProgramMemberId();
    }
  };

  componentDidMount() {
    this.handleInitialValues();
  }

  componentDidUpdate(prevProps) {
    const { initialValues, clearVehiclesFilterData, getVehiclesList, setSelectedCurrencyToggleData, values } =
      this.props;
    if (
      prevProps.initialValues?.pickupLocation.name !== initialValues?.pickupLocation.name ||
      prevProps.initialValues?.returnLocation.name !== initialValues?.returnLocation.name
    ) {
      clearVehiclesFilterData();
      getVehiclesList(initialValues?.pickupLocation?.address?.country_code);
      setSelectedCurrencyToggleData();
    }
    if (
      prevProps.initialValues?.pickupLocation?.name !== initialValues?.pickupLocation?.name ||
      prevProps.initialValues?.returnLocation?.name !== initialValues?.returnLocation?.name
    ) {
      this.handleInitialValues();
    }
    // Updating the pickup and return location based on the initiate request from redux state.
    if (
      !values?.pickupLocation?.name &&
      (prevProps.values?.pickupLocation?.name !== values?.pickupLocation?.name ||
        prevProps.values?.returnLocation?.name !== values?.returnLocation?.name)
    ) {
      this.setState({
        initialValues: {
          ...this.props.initialValues,
          pickupLocation: values?.pickupLocation,
          returnLocation: values?.returnLocation,
        },
      });
    }
  }

  validate = (values) => {
    const errors = {};

    if (!values.pickupLocation) {
      errors.pickupLocation = utils.i18n('bw_pickup_location_submit_error');
    }

    if (this.state.isOneWay && !values.returnLocation) {
      errors.returnLocation = utils.i18n('bw_return_location_submit_error');
    }

    return errors;
  };

  handleSetLocation =
    (exchangeType = EXCHANGE_TYPE_PICKUP) =>
    (location) => {
      const { setLocation, setIsGmiUIOneWayRental } = this.props;
      const { isOneWay } = this.state;

      if (!isOneWay) {
        setLocation(location);
      } else {
        setLocation(location, exchangeType);
      }
      setIsGmiUIOneWayRental(isOneWay); // updating one way redux state true/false
      this.setState({ isCollapsed: false });
    };

  handleUnsetLocation =
    (exchangeType = EXCHANGE_TYPE_PICKUP) =>
    () => {
      const { unsetLocation, setIsGmiUIOneWayRental } = this.props;
      const { isOneWay } = this.state;

      if (!isOneWay) {
        unsetLocation(EXCHANGE_TYPE_RETURN);
      }
      setIsGmiUIOneWayRental(isOneWay);
      unsetLocation(exchangeType);
    };

  onToggleOneWay = (form) => () => {
    this.setState(
      ({ isOneWay }) => ({ isOneWay: !isOneWay, isCollapsed: false }),
      () => {
        const { setLocation, values, setIsGmiUIOneWayRental } = this.props;
        const { pickupLocation } = values;
        const { isOneWay } = this.state;

        if (isOneWay && this._returnLocation) {
          setLocation(this._returnLocation, EXCHANGE_TYPE_RETURN);
          form.change('returnLocation', this._returnLocation);
        } else if (!isOneWay && pickupLocation) {
          setLocation(pickupLocation, EXCHANGE_TYPE_RETURN);
          form.change('returnLocation', pickupLocation);
        } else {
          this.props.unsetLocation(EXCHANGE_TYPE_RETURN);
          form.change('returnLocation', '');
        }
        setIsGmiUIOneWayRental(isOneWay);
      }
    );
  };

  handleClose = () => {
    this.props.onCloseDrawer();

    // if you're on vehicle select, and choose a new branch location, you'll remain on vehicle select
    // so we cannot rely on a route update to close the progressbar
    this.props.handleCollapseProgressBar();
  };

  handleSubmit = () => {
    const { values, setLocation, isTrueModify, unsetLocationData } = this.props;
    const { pickupLocation, oneWayRental, returnLocation } = values;

    unsetLocationData();
    if (this.state.isOneWay && !oneWayRental && isTrueModify) {
      this.setState(
        ({ isOneWay }) => ({ isOneWay: !isOneWay, isCollapsed: false }),
        () => {
          setLocation(pickupLocation, EXCHANGE_TYPE_RETURN);
        }
      );
    }
    setTimeout(() => {
      this.props.onSubmit().then((response) => {
        if (!response.messages) {
          this.setState({ didInitiate: true }, this.handleClose);
        }
      });
      // Adds text to loading-overlay if user update location selection
      const overlay = document.querySelector('.loading-overlay');
      pickupLocation?.type === 'CITY' || returnLocation?.type === 'CITY'
        ? (overlay.innerHTML = `<p>${utils.i18n('finding_your_locations')}</p>`)
        : (overlay.innerHTML = `<p>${utils.i18n('loading_your_vehicles')}</p>`);
    }, 50);
  };

  renderNorthAmericanPrepayViewOnly() {
    const { values, showCancelModal, breakpoint } = this.props;
    const { pickupLocation, returnLocation, oneWayRental } = values;
    const { isMobile } = breakpoint;

    return (
      <>
        <section className='progress-bar__location'>
          <p className='progress-bar__location-display'>
            <span>
              {pickupLocation.name}
              {pickupLocation.airport_code && ` (${pickupLocation.airport_code})`}
            </span>
            {oneWayRental && (
              <span className='progress-bar__return-location-display'>
                {returnLocation.name}
                {returnLocation.airport_code && ` (${returnLocation.airport_code})`}
              </span>
            )}
          </p>
          <p className='progress-bar__location-cancel-info'>
            {utils.i18n('progress_bar_location_cancel_rebook_info')}{' '}
            <Button link onClick={showCancelModal} className='progress-bar__cancel-cta'>
              {utils.i18n('progress_bar_location_cancel_cta')}
            </Button>
          </p>
        </section>
        {isMobile && (
          <Button type='button' className='progress-bar__drawer-close' onClick={this.handleClose}>
            {utils.i18n('common_close')}
          </Button>
        )}
      </>
    );
  }

  render() {
    const { label, hasSelectedVehicle, values, isNAPrepayModify, history, isFetchingReservation } = this.props;
    const { isOneWay } = this.state;
    const { pickupLocation, returnLocation } = values;
    const pickupInputLabel = utils.i18n(
      isOneWay ? 'location_search_placeholder' : 'location_search_pickup_and_return_placeholder'
    );

    return (
      <ProgressBarDrawerWrapper>
        <div className='progress-bar__drawer'>
          <h2 className='progress-bar__drawer-title'>{utils.i18n(label)}</h2>
          <ServiceErrors statePath={GMI_SERVICE_PATHS.RESERVATIONS_INITIATE} />
          {isNAPrepayModify ? (
            this.renderNorthAmericanPrepayViewOnly()
          ) : (
            <>
              <Form onSubmit={this.handleSubmit} initialValues={this.state.initialValues} validate={this.validate}>
                {({ form, handleSubmit }) => (
                  <form className='progress-bar__location-form' onSubmit={handleSubmit} autoComplete='off'>
                    <div className='progress-bar__location-fields'>
                      <div className={cn('fieldset fieldset--location-search ', { 'round-trip': !isOneWay })}>
                        <LocationSearch
                          type={LOCATIONS.LOCATION_SEARCH_TYPES.BOOKING_WIDGET}
                          id='pickupLocation'
                          name='pickupLocation'
                          label={pickupInputLabel}
                          setLocation={this.handleSetLocation()}
                          unsetLocation={this.handleUnsetLocation()}
                          toggleOneWay={!isOneWay ? this.onToggleOneWay(form) : null}
                          isOneWay={isOneWay}
                          selectedLocation={pickupLocation}
                          currentPage={history?.location?.pathname}
                          isFetchingReservation={isFetchingReservation}
                        />
                      </div>
                      {isOneWay && (
                        <div className='fieldset fieldset--location-search'>
                          <LocationSearch
                            type={LOCATIONS.LOCATION_SEARCH_TYPES.BOOKING_WIDGET}
                            id='returnLocation'
                            name='returnLocation'
                            label={utils.i18n('location_search_return_placeholder')}
                            setLocation={this.handleSetLocation(EXCHANGE_TYPE_RETURN)}
                            unsetLocation={this.handleUnsetLocation(EXCHANGE_TYPE_RETURN)}
                            toggleOneWay={this.onToggleOneWay(form)}
                            isOneWay={isOneWay}
                            selectedLocation={returnLocation}
                          />
                        </div>
                      )}
                    </div>
                    <Button
                      data-dtm-track={utils.analytics.dtm(
                        ANALYTICS.UI_BUTTON,
                        ANALYTICS.PROGRESS_BAR,
                        'update_location'
                      )}
                      type='submit'
                      className='progress-bar__drawer-submit'
                    >
                      {utils.i18n('progress_bar_submit_value')}
                    </Button>
                  </form>
                )}
              </Form>
              {hasSelectedVehicle && (
                <p className='progress-bar__drawer-help-text'>{utils.i18n('progress_bar_location_help_text')}</p>
              )}
            </>
          )}
        </div>
      </ProgressBarDrawerWrapper>
    );
  }
}

ProgressBarLocationDrawer.propTypes = {
  // ProgressBar props
  isFetchingReservation: PropTypes.bool,
  // container props
  breakpoint: PropTypes.object,
  values: PropTypes.object,
  initialValues: PropTypes.object,
  hasSelectedVehicle: PropTypes.bool,
  isNAPrepayModify: PropTypes.bool,
  history: PropTypes.object,
  partner_reward_program: PropTypes.object,
  // container actions
  setLocation: PropTypes.func,
  unsetLocation: PropTypes.func,
  onSubmit: PropTypes.func,
  showCancelModal: PropTypes.func,
  // Progress Bar config props
  label: PropTypes.string,
  onCloseDrawer: PropTypes.func,
  handleCollapseProgressBar: PropTypes.func,
  setSelectedCurrencyToggleData: PropTypes.func,
  setPartnerRewardProgram: PropTypes.func,
  setPartnerRewardProgramMemberId: PropTypes.func,
};

export default ProgressBarLocationDrawer;
