import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import utils from 'utils';
import { MODAL, GMI_SERVICE_PATHS } from 'constants';
import { breakpointSelector, isFetchingEndpointSelector, rateTypesSelector } from 'selectors';
import { selectCarClass } from "actions/reservation/selectCarClass";
import { getCarClassDetails } from "actions/reservation/getCarClassDetails";
import { openModal } from "actions/modal/openModal";
import { openModalWithProps } from "actions/modal/openModalWithProps";
import VehicleSelectDetailsCard from './VehicleSelectDetailsCard';

const carClassDetailsServiceCode = `${GMI_SERVICE_PATHS.CAR_CLASS_DETAILS}?carClassCode=`;

const mapStateToProps = (state, props) => {
  const vehicleCode = props.vehicle.code;
  const { payLaterRates, prePayRates } = rateTypesSelector(state, { vehicleCode });
  return {
    breakpoint: breakpointSelector(state),
    payLaterRates,
    prePayRates,
    loadingDetails: isFetchingEndpointSelector(state, { endpoint: carClassDetailsServiceCode + vehicleCode }),
  };
};

const actionCreators = {
  getCarClassDetails,
  selectCarClass,
  showTaxesFeesModal: (header, feesArray, taxesFeesTotal) =>
    openModalWithProps(MODAL.TAXES_FEES_MODAL, { header, feesArray, taxesFeesTotal }),
  showConvertedTotalsModal: () => openModal(MODAL.CURRENCY_CONVERSION_DETAILS_MODAL),
  showGuaranteedResModal: () => openModal(MODAL.GUARANTEED_RES),
  showCouponDoesNotApplyModal: continueWithoutCoupon =>
    openModalWithProps(MODAL.COUPON_DOES_NOT_APPLY, { continueWithoutCoupon }),
};

const actionCreatorsDispatchedManually = (dispatch, props) => ({
  showTermsOfUseModal: (code, description, prepay) => {
    const finalAction = () => dispatch(selectCarClass({ code, prepay }));
    dispatch(openModalWithProps(MODAL.VAN_SELECTED_12_AND_15_TERMS_USE, { code, description, finalAction }));
  },
  showLimitedInventoryVehicleModal: (code, prepay) => {
    const finalAction = () => dispatch(selectCarClass({ code, prepay }));
    dispatch(openModalWithProps(MODAL.LIMITED_INVENTORY_VEHICLE_MODAL, { finalAction }));
  },
  learnAboutPrepayAndPayLaterModal: () =>
    dispatch(
      openModalWithProps(MODAL.LEARN_ABOUT_PREPAY_AND_PAY_LATER, { guaranteedRes: props.vehicle.guaranteed_vehicle })
    ),
});

const mapDispatchToProps = (dispatch, props) => ({
  ...bindActionCreators(actionCreators, dispatch),
  ...actionCreatorsDispatchedManually(dispatch, props),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(utils.toJS(VehicleSelectDetailsCard));
