import React from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import utils from 'utils';
import { MODAL } from 'constants';
import { isCheckedInReservationSelector } from 'selectors/reservationFlowSelectors';
import useActions from 'hooks/useActions';
import Button from 'components/Button';
import useAdditionalDriversList from 'hooks/useAdditionalDriversList';
import { startAdditionalDriverFlow } from "actions/reservation/startAdditionalDriverFlow";
import { openModal } from "actions/modal/openModal";

const AdditionalDriversListItem = ({ driver, isCancelled }) => {
  const additionalDriverFlowStart = useActions(startAdditionalDriverFlow);
  const isCheckedIn = useSelector(isCheckedInReservationSelector);
  const openAdditionalDriverOciRequestModal = useActions(() => openModal(MODAL.ADDITIONAL_DRIVER_OCI_REQUEST_MODAL));
  const driverDetailsComplete = !!driver.individual_id;
  const addLicenseHandler = isCheckedIn ? additionalDriverFlowStart : openAdditionalDriverOciRequestModal;
  const { hasYoungDriverFee } = useAdditionalDriversList();

  return (
    <div className='additional-driver-confirmation__list-item'>
      <p className='driver-list-item__name'>
        {`${driver.first_name} ${driver.last_name}`}
        <span
          className={cn(
            'driver-list-item__driver-details-checkmark',
            driverDetailsComplete && 'driver-list-item__driver-details-checkmark--completed'
          )}
        />

        {hasYoungDriverFee(driver?.individual_id) && (
          <span className='driver-list-item__driver-fee'>{utils.i18n('additional_driver_young_driver_fee')}</span>
        )}
      </p>

      {!driverDetailsComplete && !isCancelled && (
        <Button className='driver-list-item__action--add-license' link onClick={addLicenseHandler}>
          {utils.i18n('additional_driver_add_license_button')}
        </Button>
      )}
    </div>
  );
};

export default AdditionalDriversListItem;
