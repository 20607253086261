import PropTypes from 'prop-types';

export const propTypes = {vehicleName: PropTypes.string,
  code: PropTypes.string,
  makeModelOrSimilarText: PropTypes.string,
  features: PropTypes.array,
  peopleCapacity: PropTypes.number,
  luggageCapacity: PropTypes.number,
  twoOrFourDoorText: PropTypes.string,
  images: PropTypes.array,
  numberOfExtrasSelected: PropTypes.number,
  breakpoint: PropTypes.object,
  coupons: PropTypes.array.isRequired,};
