import React from 'react';
import utils from 'utils';
import useActions from 'hooks/useActions';
import { analyticsInteraction } from 'utils/analytics';
import ANALYTICS from 'constants/analytics';
import { upcomingRental } from 'actions/modifyRental';
import Title from 'components/Title';
import Button from 'components/Button';
import { onSubmitRentalByPhone } from 'components/ViewModifyCancelApp/redux/actions';

const RentalTypeOptions = () => {
  const onSubmitUpcomingRentalAction = useActions(upcomingRental);
  const onSubmitRentalByPhoneAction = useActions(onSubmitRentalByPhone);

  const currentRentalClick = () => {
    analyticsInteraction(ANALYTICS.UI_BUTTON, ANALYTICS.CONFIRM_MODIFY, ANALYTICS.FIND_CURRENT_RENTAL);
    onSubmitRentalByPhoneAction();
  };
  const upcomingRentalClick = () => {
    // Redirect to View/Modify/Cancle Screen
    analyticsInteraction(ANALYTICS.UI_BUTTON, ANALYTICS.CONFIRM_MODIFY, ANALYTICS.FIND_UPCOMING_RENTAL);
    onSubmitUpcomingRentalAction();
  };

  return (
    <section className='rentaltype-option padding-bottom--condensed'>
      <Title type='h3' className='rentaltype-option__heading' text={utils.i18n('what_are_you_looking_for')} />
      <div className='rentaltype-option__links'>
        <Button
          link
          className='rentaltype-option__links-button btn-current-rental link--arrow'
          onClick={currentRentalClick}
          ariaText={utils.i18n('find_a_current_rental')}
        >
          <span>{utils.i18n('find_a_current_rental')}</span>
        </Button>
      </div>
      <div className='rentaltype-option__links'>
        <Button
          link
          className='rentaltype-option__links-button btn-upcoming-rental link--arrow'
          onClick={upcomingRentalClick}
          ariaText={utils.i18n('find_an_upcoming_rental')}
        >
          <span>{utils.i18n('find_an_upcoming_rental')}</span>
        </Button>
      </div>
    </section>
  );
};

export default RentalTypeOptions;
