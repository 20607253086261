import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import useActions from 'hooks/useActions';
import { MODAL } from 'constants';
import utils from 'utils';
import { analyticsInteraction } from 'utils/analytics';
import ANALYTICS from 'constants/analytics';
import Button from 'components/Button';
import FieldsState from 'components/Form/FieldsState';
import {
  getOnlineCheckInProfileOciEligible,
  onlineCheckInStatusSelector,
  isUnauthUserWithLoyaltyProfileSelector,
  onlineCheckInProfileSelector,
} from 'selectors/checkinSelectors';
import { authenticatedSelector } from 'selectors/profileSelectors';
import {
  isSkipTheCounterCompletedReservationSelector,
  isCheckedInReservationSelector,
} from 'selectors/reservationFlowSelectors';
import Sort from 'components/SortFilterWrapper/Sort/Sort';
import { openModal } from "actions/modal/openModal";
import InResDriverDetailsRow from './CheckInCard/InResDriverDetailsRow';

/**
 * AcceleratedCheckInTile component shows tile on Review page to start In Res Oci flow
 *
 * @return {JSX}  AcceleratedCheckInTile component
 */
const AcceleratedCheckInTile = ({
  handleSortUpdate,
  ociComplete,
  handleEditProfile,
  showInResOCIActivatedProfile,
  isEditingProfileDetails,
  isOciFormOpen,
  isPayLater,
}) => {
  const [showEditAuthUser] = useState(false);
  // This is in state because once partial profile activates oci, it can not act like auto checkin
  const [authPartialProfileExists, setAuthPartialProfileExists] = useState(false);
  const ociEligible = useSelector(getOnlineCheckInProfileOciEligible);
  const isResOCIActivated = useSelector(isCheckedInReservationSelector);
  const isSkippingTheCounter = useSelector(isSkipTheCounterCompletedReservationSelector);
  const { driverProfileComplete, contactProfileComplete } = useSelector(onlineCheckInStatusSelector);
  const profile = useSelector(onlineCheckInProfileSelector);
  const isAuth = useSelector(authenticatedSelector);
  const isUnauthUserWithLoyaltyProfile = useSelector(isUnauthUserWithLoyaltyProfileSelector);

  const openSignInModal = useActions(() => openModal(MODAL.SIGN_IN_MODAL));
  const openLearnMoreAboutOCIInactiveModal = useActions(() =>
    openModal(MODAL.LEARN_MORE_ABOUT_ACCELERATED_CHECKIN_INACTIVE_MODAL)
  );
  const checkInActivated = isResOCIActivated || ociEligible;
  const isAutoCheckin =
    checkInActivated && isAuth && !showEditAuthUser && !isSkippingTheCounter && !authPartialProfileExists;
  const isAuthLoyaltyProfile =
    isAuth &&
    !authPartialProfileExists &&
    profile?.loyalty_data &&
    profile?.loyalty_data.loyalty_program !== 'Non-Loyalty';

  const showSignInEditOption = isUnauthUserWithLoyaltyProfile && showInResOCIActivatedProfile && !isSkippingTheCounter;
  const foundProfileComplete = driverProfileComplete && contactProfileComplete;

  useEffect(() => {
    setAuthPartialProfileExists(isAuth && (!driverProfileComplete || !contactProfileComplete));
  }, [isAuth]);

  const handleAnalyticsForEditProfile = (value) => {
    const key = value === 'driver' ? ANALYTICS.EDIT_DRIVER_LICENSE : ANALYTICS.EDIT_CONTACT_INFORMATION;
    analyticsInteraction(ANALYTICS.SUB_PAGE, key, ANALYTICS.OPEN);
    handleEditProfile(value);
  };

  const completeCheckmark = (
    <span className='review__section-card__header__complete-indicator'>
      <span className='review__section-card__header__complete-indicator__label'>
        {utils.i18n('review_section_card_complete_indicator')}
      </span>
    </span>
  );

  const Title = ({ type: Tag }) => (
    <Tag
      className={cn('in-res-oci-review-tile__title', {
        'in-res-oci-review-tile__title--skip-the-counter': isSkippingTheCounter,
        'in-res-oci-review-tile__title--active':
          isAutoCheckin || (showInResOCIActivatedProfile && !isSkippingTheCounter),
      })}
    >
      <span className={'in-res-oci-review-tile__title'}>
        {isSkippingTheCounter ? utils.i18n('trip_card_title_skip_the_counter') : utils.i18n('check_in_title')}
      </span>

      {((!isAutoCheckin && !showInResOCIActivatedProfile) || !showInResOCIActivatedProfile) && (
        <span key='0' className='in-res-oci-review-tile__optional-copy'>
          {` ${utils.i18n('common_optional')}`}
        </span>
      )}

      {isAuthLoyaltyProfile && showInResOCIActivatedProfile && !isSkippingTheCounter && (
        <>
          <span
            className={cn('in-res-oci-review-tile__activated-label', {
              'in-res-oci-review-tile__activated-label--inactive': !checkInActivated,
            })}
          >
            {utils.i18n('in_res_check_in_activated_label')}
          </span>
        </>
      )}
    </Tag>
  );

  const fields = [];

  return (
    <div
      className={cn({
        'in-res-oci-review-tile': isAuthLoyaltyProfile && showInResOCIActivatedProfile,
        'review__section-card':
          (!isAuthLoyaltyProfile && !showInResOCIActivatedProfile) ||
          (!isAuthLoyaltyProfile && showInResOCIActivatedProfile),
        'check-in-template--open': isOciFormOpen,
        'edit-authenticated-profile': !isAuthLoyaltyProfile && showInResOCIActivatedProfile && isEditingProfileDetails,
      })}
    >
      <div className={cn('review__section-card__header oci-header')}>
        <Title type={isAuth && foundProfileComplete ? 'h4' : 'h3'} />
        {!isAuthLoyaltyProfile && showInResOCIActivatedProfile && (
          <FieldsState names={fields}>{() => completeCheckmark}</FieldsState>
        )}
      </div>

      {showInResOCIActivatedProfile && (
        <>
          {isAuthLoyaltyProfile && !isSkippingTheCounter && (
            <p className='in-res-oci-review-tile__description'>
              {utils.i18n('in_res_oci_review_tile_description_authenticated')}
            </p>
          )}

          {!isAuthLoyaltyProfile && !isSkippingTheCounter && (
            <>
              <p className='in-res-oci-review-tile__title--info'>{utils.i18n('check_in_great_thanks')}</p>
              <p className='in-res-oci-review-tile__description'>
                {utils.i18n('in_res_oci_review_sub_title_desc_check_in_activated')}
              </p>
            </>
          )}

          {!isEditingProfileDetails && (
            <div className='in-res-oci-review-tile__details-row'>
              <InResDriverDetailsRow handleEditProfile={handleAnalyticsForEditProfile} hideCompletedIcon />
            </div>
          )}
        </>
      )}
      {showSignInEditOption && (
        <div className='in-res-oci-modal__signin'>
          <h4>{utils.i18n('check_in_update_account_subtitle')}</h4>
          <p>
            {utils.i18n(
              'check_in_sign_in_cta',
              [
                <Button key={0} link linkText onClick={() => openSignInModal()}>
                  {utils.i18n('common_sign_in')}
                </Button>,
              ],
              { jsx: true }
            )}
          </p>
        </div>
      )}

      {!isAuthLoyaltyProfile &&
        !checkInActivated &&
        ((!ociComplete && !isSkippingTheCounter) || (ociComplete && !showInResOCIActivatedProfile)) && (
          <div className='in-res-oci-save-time'>
            <p className='in-res-oci-review-tile__description'>
              {utils.i18n('check_in_title_info')}&nbsp;
              <Button
                key={0}
                className='in-res-oci-review-tile__learn-more'
                link
                onClick={openLearnMoreAboutOCIInactiveModal}
              >
                {`${utils.i18n('common_learn_more')}`}
              </Button>
            </p>
            <ul className='in-res-oci-review-tile__info-list'>
              {isPayLater && <li>{utils.i18n('confirmation_oci_check_in_show_more_item_1')}</li>}
              <li>{utils.i18n('confirmation_oci_check_in_show_more_item_2')}</li>
              <li>{utils.i18n('confirmation_oci_check_in_show_more_item_3')}</li>
            </ul>
            <Sort
              isRequired={false}
              hideTheme={true}
              updateSort={handleSortUpdate}
              customTitle={utils.i18n('trip_card_oci_and_save_time_at_pick_up')}
              analyticsInteractionKey={'accelerate check-in'}
              analyticsInteractionSkipFor={'yes'}
              buttons={[
                {
                  label: utils.i18n('trip_card_yes_save_time_at_pick_up'),
                  value: 'yes',
                },
                {
                  label: utils.i18n('trip_card_no_save_time_at_pick_up'),
                  value: 'no',
                },
              ]}
            />
          </div>
        )}
    </div>
  );
};

export default AcceleratedCheckInTile;
