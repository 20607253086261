import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { i18n } from 'utils/i18n';
import { RESERVATIONS } from 'constants';
import TextField from 'components/Form/TextField';
import RadioGroup from 'components/Form/RadioGroup';
import FieldGroupCard from '../FieldGroupCard';

class BillingAccountCard extends Component {
  state = {
    showBillingNumberInput: false,
    clearBillingNumber: false,
  };

  componentDidMount() {
    const {
      contractBillingAccount,
      billingAccountType,
      updateBillingAccount,
      updateBillingAccountType,
      billingAccountInitialValue,
      isDeeplink,
      form,
    } = this.props;
    if (contractBillingAccount || billingAccountType === RESERVATIONS.BILLING_TYPE_EXISTING) {
      updateBillingAccountType(RESERVATIONS.BILLING_TYPE_EXISTING);
    } else if (billingAccountType === RESERVATIONS.BILLING_TYPE_CUSTOM) {
      updateBillingAccountType(RESERVATIONS.BILLING_TYPE_CUSTOM);
      form.change('billing.account', billingAccountInitialValue);
      form.change('billing.selection', 'billing-number');
    } else {
      // contract_accepts_billing && !contract_billing_account
      // set default value to be pay-at-pickup
      form.change('billing.account', '');
      form.change('billing.selection', 'pay-at-pickup');
    }
    (contractBillingAccount || billingAccountInitialValue) &&
      updateBillingAccount(contractBillingAccount || billingAccountInitialValue);

    if (!isDeeplink && billingAccountInitialValue) {
      this.setState({ showBillingNumberInput: true });
    }
  }

  onSelect = (e, input) => {
    const {
      contractBillingAccount,
      billingAccountType,
      updateBillingAccountType,
      updateBillingAccount,
      clearBillingAccount,
      updatePaymentIds,
      isDeeplink,
      billingAccountInitialValue,
      form,
    } = this.props;

    if (input.value === 'billing-number') {
      if (
        contractBillingAccount ||
        billingAccountType === RESERVATIONS.BILLING_TYPE_EXISTING ||
        (isDeeplink && billingAccountInitialValue)
      ) {
        updateBillingAccountType(RESERVATIONS.BILLING_TYPE_EXISTING);
        updateBillingAccount(contractBillingAccount || billingAccountInitialValue);
      } else {
        this.setState({ showBillingNumberInput: true });
        updateBillingAccountType(RESERVATIONS.BILLING_TYPE_CUSTOM);
        this.setState({ clearBillingNumber: true });
      }
    } else {
      clearBillingAccount();
      form.change('billing.account', '');

      updatePaymentIds?.(''); // only for true modify otherwise updatePaymentIds is a boolean not a function
      if (!contractBillingAccount) {
        this.setState({ showBillingNumberInput: false });
      }
    }
  };

  render() {
    const { contractBillingAccount, updateBillingAccount, billingAccountInitialValue, isDeeplink } = this.props;
    const { showBillingNumberInput } = this.state;
    const { clearBillingNumber } = this.state;

    return (
      <FieldGroupCard title={i18n('review_billing_account_card_title')}>
        <p className='review__section-card__description'>{i18n('review_billing_account_card_description')}</p>

        <RadioGroup
          name='billing.selection'
          onChange={this.onSelect}
          errorMessage={i18n('review_billing_account_required_error')}
          buttons={[
            {
              label: i18n('review_billing_account_pay_at_pickup_label'),
              value: 'pay-at-pickup',
              description: i18n('review_billing_account_pay_at_pickup_description'),
            },
            {
              label: i18n('review_billing_account_billing_number_label'),
              value: 'billing-number',
              description: i18n(
                contractBillingAccount
                  ? 'review_billing_account_single_billing_number_description'
                  : 'review_billing_account_billing_number_description'
              ),
            },
          ]}
        />

        {(contractBillingAccount || (isDeeplink && billingAccountInitialValue)) && (
          <span className='review__section-card__account-number'>
            <b>{i18n('review_billing_account_account_number')}</b>{' '}
            {contractBillingAccount || billingAccountInitialValue}
          </span>
        )}

        {showBillingNumberInput && (!contractBillingAccount || !billingAccountInitialValue) && (
          <TextField
            name='billing.account'
            label={i18n('review_billing_account_billing_number_input_label')}
            onChange={updateBillingAccount}
            fill
            required
            initialValueButton={clearBillingNumber ? '' : billingAccountInitialValue}
          />
        )}
      </FieldGroupCard>
    );
  }
}

BillingAccountCard.propTypes = {
  contractBillingAccount: PropTypes.string,
  billingAccountType: PropTypes.string,
  updateBillingAccount: PropTypes.func.isRequired,
  updateBillingAccountType: PropTypes.func.isRequired,
  clearBillingAccount: PropTypes.func.isRequired,
  updatePaymentIds: PropTypes.func,
  billingAccountInitialValue: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  form: PropTypes.object,
};

export default BillingAccountCard;
