import React, { forwardRef, useEffect, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import utils from 'utils';
import useActions from 'hooks/useActions';
import { THEMES } from 'constants';
import Checkbox from 'components/Form/Checkbox';
import SelectField from 'components/Form/SelectField';
import cn from 'classnames';
import { sortArrayOfObjectsByKeyStringToNumber } from 'utils/array';
import { useSelector } from 'react-redux';
import { getUiStatePassengersFilterSelector } from 'selectors/uiStateSelectors';
import { carClassFiltersSelector } from 'selectors/reservationSelectors';
import { setFiltersByValue } from "actions/filter/setFiltersByValue";
import ClearAllButton from '../ClearAllButton';

const Filter = forwardRef(({
  breakpoint,
  config,
  currentFilters = [],
  hideDesktopHeader,
  handleClearAll,
  onChange,
  storeKey,
  lowestCarFilterPrices,
  numFiltersSelected,
  destinationCurrencySelected,
}, ref) => {
  const setFiltersByValueAction = useActions(setFiltersByValue);
  const passengersCheckboxes = useSelector(getUiStatePassengersFilterSelector);
  const carClassFilters = useSelector((state) => utils.safeToJS(carClassFiltersSelector(state)));

  const handleFilterChange = (params) => {
    setFiltersByValueAction({ storeKey, currentFilters, ...params });
    utils.safeExecute(onChange, currentFilters, params.value);
  };

  useImperativeHandle(ref, () => ({
    reset: () => {
      handleClearAll()
    }
  }))
  const handleCheckboxChange =
    ({ keyPath, isRadio, isSingleSelect, value, isPassenger }) =>
      (e, input, checked) => {
        let valueToSend = value;

        handleFilterChange({ value: valueToSend, checked, keyPath, isRadio, isSingleSelect });
      };

  const handleSelectChange =
    ({ keyPath, isRadio, isSingleSelect }) =>
      (e) => {
        handleFilterChange({ value: e.target.value, keyPath, isRadio, isSingleSelect });
      };

  const decorate = (key) => (value) => utils.i18n(key, { 0: value });

  return currentFilters.map((filter, index) => {
    const { available, category, keyPath } = filter;
    let availableSorted =
      category !== 'Vehicle Class' ||
        keyPath !== 'filters.PASSENGERS.filter_code' ||
        keyPath !== 'filters.PASSENGERS.filterCode'
        ? utils.gmi.sortArrayOfObjectsByKey(available, 'label')
        : available;
    const isPassenger = keyPath === 'filters.PASSENGERS.filter_code' || keyPath === 'filters.PASSENGERS.filterCode';

    const carClassFiltersFiltered = carClassFilters?.find((data) => {
      const value = data.filter_code;
      if (value && keyPath && keyPath.includes(value)) {
        return data;
      }
      return null;
    });

    // Custom sorting for passengers filter since values & labels are comming as strings and not numbers
    // and gmi filter wasn't sorting them correctly
    if (isPassenger) {
      availableSorted = sortArrayOfObjectsByKeyStringToNumber(available, 'value');
    }

    const filterConfig = config.find((item) => item.keyPath === keyPath);

    if (filterConfig) {
      const { optionDecorateKey, isSingleSelect, labelHandler, showTotalLabel } = filterConfig;
      const { type, isRadio } = filterConfig;

      let categoryi18n = category;

      if (keyPath === 'filters.DRIVE.filter_code') {
        categoryi18n = 'vehicle_filter_drive_type';
      }
      return (
        <fieldset key={keyPath + index} className={`filter__fieldset filter__fieldset--${type}`}>
          {type === 'checkbox' && (
            <>
              <legend className='filter__fieldset-heading filter__legend'>
                {utils.i18n(categoryi18n)}
                {showTotalLabel && (
                  <span className='filter__fieldset-heading-total'>
                    {utils.i18n('vehicle_select_filter_total_from_label')}
                  </span>
                )}
              </legend>
              {index === 0 && !breakpoint.isMobile && hideDesktopHeader && numFiltersSelected > 0 && (
                <ClearAllButton hideDesktopHeader={hideDesktopHeader} handleClearAll={handleClearAll} />
              )}

              <div
                className={cn('filter__options', {
                  'filter__options--with-price': showTotalLabel,
                })}
              >
                {type === 'checkbox' &&
                  availableSorted.map(({ checked, label, value }) => {
                    if (value === 'null') {
                      return null;
                    }
                    const filteredValues = carClassFiltersFiltered?.filter_values?.find((item) => item.code === value);
                    const finalLabel = filteredValues?.description || label || value;
                    const id = `${keyPath}_${value}`;
                    return (
                      <Checkbox
                        checked={checked}
                        id={id}
                        key={id}
                        label={
                          labelHandler?.(finalLabel, value, lowestCarFilterPrices, destinationCurrencySelected) ||
                          finalLabel ||
                          value
                        }
                        name={keyPath}
                        onChange={handleCheckboxChange({
                          keyPath,
                          isRadio,
                          value,
                          isPassenger,
                        })}
                        value={value}
                        derivedState
                      />
                    );
                  })}
              </div>
            </>
          )}
          {type === 'select' && (
            <SelectField
              id={keyPath}
              name={keyPath}
              onChange={handleSelectChange({ keyPath, isRadio, isSingleSelect })}
              options={availableSorted}
              includeAllOption
              label={category}
              theme={THEMES.DARK_BLUE}
              decorate={optionDecorateKey ? decorate(optionDecorateKey) : null}
            />
          )}
        </fieldset>
      );
    }

    return null;
  });
});

Filter.propTypes = {
  storeKey: PropTypes.string.isRequired,
  resultsCount: PropTypes.number,
  breakpoint: PropTypes.object,
  config: PropTypes.array.isRequired,
  currentFilters: PropTypes.array,
  hideDesktopHeader: PropTypes.bool,
  handleClearAll: PropTypes.func,
  onChange: PropTypes.func,
  lowestCarFilterPrices: PropTypes.object,
  numFiltersSelected: PropTypes.number,
};

export default Filter;
