import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import utils from 'utils';
import ANALYTICS from 'constants/analytics';
import { MODAL, MODAL_THEMES, MODAL_BUTTON_TYPES } from 'constants';
import Modal from 'components/Modal';
import { ModalFooter } from 'components/Modal/ModalFooter';
import AdditionalDriverDetailsForm from 'components/AdditionalDriverFlow/AdditionalDriverDetailsForm';

import { getShowAdditionalDriverIsSpouseSelector } from 'selectors/reservationSelectors';

/**
 * AdditionalDriverDetailsModal - Additional Driver Details Modal
 * @return {JSX}
 */
const AdditionalDriverDetailsModal = () => {
  const isSpouse = useSelector(getShowAdditionalDriverIsSpouseSelector);

  const [isSpouseLocalState, setIsSpouseLocalState] = useState(false);
  const [hideBanner, setHideBanner] = useState(false);

  const modalKey = MODAL.ADDITIONAL_DRIVER_DETAILS_MODAL;

  const renderFooter = (formSubmit) => {
    const buttons = [
      {
        type: MODAL_BUTTON_TYPES.DECLINE,
        label: utils.i18n('common_cancel'),
        analyticsKey: utils.analytics.prepareKey(modalKey),
        analyticsValue: utils.i18n('common_cancel').toLowerCase(),
      },
      {
        type: MODAL_BUTTON_TYPES.ACCEPT,
        label: utils.i18n('common_save'),
        handler: formSubmit,
        analyticsHandler: () =>
          utils.analytics.interaction(ANALYTICS.MODAL, utils.analytics.prepareKey(modalKey), ANALYTICS.SAVE),
      },
    ];

    return <ModalFooter buttons={buttons} disableStickyFooter />;
  };

  const onCloseModal = () => {
    if (isSpouse) {
      setHideBanner(true);
    } else if (!isSpouse && isSpouseLocalState) {
      setIsSpouseLocalState(false);
    }
  };

  return (
    <Modal
      modalKey={modalKey}
      header={utils.i18n('additional_driver_details_modal_title')}
      customClass='in-res-oci-modal'
      theme={MODAL_THEMES.WHITE}
      onClose={onCloseModal}
    >
      {({ scrollToModalTop, handleClose, driver }) => (
        <>
          <AdditionalDriverDetailsForm
            driver={driver}
            onSubmitSuccess={handleClose}
            onSubmitFail={scrollToModalTop}
            renderFooter={renderFooter}
            isSpouseLocalState={isSpouseLocalState}
            setIsSpouseLocalState={setIsSpouseLocalState}
            hideBanner={hideBanner}
            modalKey={modalKey}
          />
        </>
      )}
    </Modal>
  );
};

export default AdditionalDriverDetailsModal;
